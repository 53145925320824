import React, { useEffect,useState } from "react";
import '../style/Modal.css';
import ModalChoice from "./ModalChoice";
import ModalAfterChoice from "./modalAfterChoice";

function ModalArticles({ data, input, dataUpdate }) {

  const [fieldNames, setFieldNames] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedPubMedId, setSelectedPubMedId] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [tripleData, setTripleData] = useState([]);
  const [tripleErr, setTripleErr] = useState(false);
  const [tripleLoading, setTripleLoading] = useState(true);
  
  const rel = [
    "mirna-go",
    "mirna-disease",
    "mirna-chemical",
    "lncrna-disease",
    "lncrna-mrna",
    "lncrna-gene",
    "snp-snorna",
    "lncrna-go",
    "snorna-go",
    "mrna-chemical",
    "mrnavaccine-disease",
    "snorna-disease",
    "snorna-gene",
    "snorna-lncrna",
    "mirna-gene",
    "mirna-mirna",
    "snorna-mirna",
  ];

  const getTriple = async () => {

    try {
      const query = `https://bio-viber-api.anacleto.di.unimi.it/get_graph?pmid=${selectedPubMedId}&relation=${rel[1]}}`; // constant relation for now
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error.message);
      const data = "Error";
      return data;
    }
    
  }

  const handleClose = async () => {
    //* Handle backdrop removal manually
    const backdrops = document.querySelectorAll('.modal-backdrop');
    for (let i = 0;i < 2;i++) {
      backdrops[i].remove();
    }

    const data = await getTriple();
    setTripleData(data);
    updateData(data);
  };


  // Manages receiving triples
  useEffect(() => {
    if (tripleData === "Error") {
      setTripleErr(true);
      setTripleLoading(false);
      console.log(tripleErr);
    }
    else if (Object.keys(tripleData).length === 0) {
      setTripleLoading(true);
    }
    else if (Object.keys(tripleData).length > 0) {
      setTripleLoading(false);
    }
  }, [tripleData]);

  // Manages receiving abstracts
  useEffect(() => {
    if (data && data.length > 0 && typeof data !== "string") {
      setFieldNames(Object.keys(data[0]));
      setIsLoading(false);
      setError(false);
    }
    if (typeof data === "string"){
      setError(true)
    }
  }, [data]);

  useEffect(() => {
    setKeyword(input)
  }, [input]);

  useEffect(() => {
    if (input !== keyword) {
      setIsLoading(true);
      setKeyword(input);
    }
  }, [input, keyword]);

  const updateData = (newData) => {
    if (newData === "Error"){
      console.log("ERROR");
    }
    dataUpdate(newData);
    // Esempio di aggiornamento dello stato con i nuovi dati
    // setDati(newData);
  };
  
  
  const handleCheckboxChange = (pubMedId) => {
    setSelectedPubMedId(pubMedId);
  };

  const handleCloseChoice = () => {
    window.location.reload();
  };



  return (
    <div>
      <ModalAfterChoice data={tripleData} />

      <div className="modal fade" id="modalGetTriple" data-bs-backdrop="static" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Getting Data...</h5>
            </div>
            <div className="modal-body">
              {tripleErr ? <p className="text-danger">Error: didn't find triple to extract, please double check parameters and try again</p> 
                      : (tripleLoading ? 
                        <div className="text-center">
                            <p className="text-warning">Please do not close the window until completion</p>
                            <div className="spinner-border" role="status"></div>
                        </div>
                        :<p className="text-success">Data received successfully</p>  
                        )
              }
                        
            </div>
            { tripleLoading ? <></> : 
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            }
          </div>
        </div>
      </div>

      {/* {<ModalChoice pubMedId={selectedPubMedId} onDataUpdate={updateData}/>} */}

      <div className="modal fade modal-xl" id="modalArticles" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Articles with keyword: {keyword}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              { error ? (<div className="text-center"><p className="text-danger">Error: Unable to send request, please double check parameters and try again</p><button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseChoice}>Close</button></div>) : (isLoading ? ( //show spinner if data is loading
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : ( //show table
                <div className="table-responsive">
                <table className="table table-striped table-bordered center-text align-middle">
                  <thead>
                    <tr>
                      {fieldNames.map((fieldName, index) => (
                        index !== 5 && index !== 3 && (
                        <th key={fieldName} className={index === 1 ? 'center-text wide-column' : 'center-text narrow-column'}>{index === 0 ? 'PubMedId' : fieldName}</th>
                      )                      
                      ))}
                      <th scope="col">selected</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className="separated-row">
                        <th scope="row" className="center-text narrow-column" ><a className="no-underline" target="_blank" href={`https://pubmed.ncbi.nlm.nih.gov/${item[fieldNames[0]]}`}>{item[fieldNames[0]]}</a></th>
                        <td className="truncate-text wide-column">
                          {typeof item[fieldNames[1]] === 'string' ? (
                            <>
                              {item[fieldNames[1]].split(' ').slice(0, 50).join(' ')}
                              {item[fieldNames[1]].split(' ').length > 50 && (
                                <span className="more-text">
                                  {item[fieldNames[1]].split(' ').slice(50).join(' ')}
                                </span>
                              )}
                            </>
                          ) : ''}
                        </td>
                        <td className="center-text narrow-column">
                          {Array.isArray(item[fieldNames[2]]) ? (
                            item[fieldNames[2]].length === 1 ? (
                              `${item[fieldNames[2]][0].firstname} ${item[fieldNames[2]][0].initials} ${item[fieldNames[2]][0].lastname}`
                            ) : (
                              `${item[fieldNames[2]][0].firstname} ${item[fieldNames[2]][0].initials} ${item[fieldNames[2]][0].lastname} etc.`
                            )
                          ) : (
                            ''
                          )}
                        </td>
                        <td className="center-text narrow-column">{item[fieldNames[4]]}</td>
                        <td className="center-text narrow-column">{item[fieldNames[6]]}</td>
                        <td className="center-text narrow-column">
                          <input 
                            type="checkbox" 
                            className="large-checkbox" 
                            checked={selectedPubMedId === item[fieldNames[0]]}
                            onChange={() => handleCheckboxChange(item[fieldNames[0]])} 
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!error && (<div className="modal-foot text-center">
                                <button type="button" 
                                      className="btn btn-success" 
                                      disabled={!selectedPubMedId} 
                                      data-bs-dismiss="modal"
                                      onClick={handleClose}
                                      data-bs-toggle="modal"
                                      data-bs-target="#modalGetTriple"
                                      >
                                        Confirm
                                </button>
                            </div>)}

              </div>
              ))}
              
            </div>

            {/*!error &&(<div className="modal-foot text-center">
              <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalChoice"  disabled={!selectedPubMedId}>Confirm</button>
            </div>)*/}
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}  

export default ModalArticles;