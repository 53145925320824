import React, { useState, useEffect } from 'react';

const CustomForm4 = ({onChangeNodeSize, onChangeEdgeSize }) => {

  const [valueNode, setValueNode] = useState(10);
  const [valueEdge, setValueEdge] = useState(1);

  const handleNode = (event) => {
    const newValue = parseFloat(event.target.value);
    setValueNode(newValue);
    onChangeNodeSize(newValue)
  };

  const handleEdge = (event) => {
    const newValue = parseFloat(event.target.value);
    setValueEdge(newValue);
    onChangeEdgeSize(newValue)
  };

  return (
    <div style={styles.container}>
      <span style={styles.subtitiles}>Select the <b>node size</b> you prefer.</span>
      <p style={{ textAlign:'center', marginBottom: '0', margin: 'auto' , marginBottom: "5%"}}>Size of nodes: {valueNode}
      <input
        type="range"
        min="1"
        max="50"
        step="1"
        value={valueNode}
        onChange={handleNode}
        style={{ width: '70%', cursor: 'grab' }}
      />
      </p>

      <span style={styles.subtitiles}>Select the <b>edge size</b> you prefer.</span>
      <p style={{ textAlign:'center', marginBottom: '0', margin: 'auto' , marginTop: "5%"}}>Size of edges: {valueEdge}
      <input
        type="range"
        min="1"
        max="8"
        step="1"
        value={valueEdge}
        onChange={handleEdge}
        style={{ width: '70%', cursor: 'grab' }}
      />
      </p>
      
    </div>
  );
};


const styles = {
  container: {
    margin: 'auto',
    backgroundColor: "white",
    paddingTop: 0,
    paddingLeft: 7,
    paddingRIght: 7,
    paddingBottom: 5,
    textAlign: 'left',
  },
  subtitiles: {
    fontSize: '75%',
    color: "black",
  },
};  

export default CustomForm4;
