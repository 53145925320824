import { useEffect, useState, useRef } from "react";
import { useSigma, useRegisterEvents } from "@react-sigma/core";
//import { render } from "@testing-library/react";

function EventsGraph({ passOnNode, passOnEdge, posx, posy, pressOnNode, pressOnEdge, viewMenuNode, viewMenuEdge  }) {
  const sigma = useSigma();     //creates an instance of Sigma, used to interact with the graph
  const registerEvents = useRegisterEvents();    //variable used for event control  
  const [draggedNode, setDraggedNode] = useState(null);     //contains the id of the dragged node - const[variable,function] = initial state value - NULL=Node not dragged yet
  const sigmaRef = useRef(sigma);

  //Every time that one of the variable in [] are modifies
  useEffect(() => {   
    
    registerEvents({
    
      //when i move the mouse inside the graph: it allows to drag a node inside the graph following the movement of the mouse
      mousemove: (e) => {  
        if (draggedNode) {    //if i get the node
            const pos = sigma.viewportToGraph(e);   //position of mouse
            //get new position of node drogged
            sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);   //set the X string of the dragged knot to the X position of the mouse
            sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);   //set the Y string of the dragged knot to the Y position of the mouse
            e.preventSigmaDefault();    //does not allow the graph to move automatically as default
            /*e.original.preventDefault();  
            e.original.stopPropagation();*/
        }
      },
      //play the same functionality of mousemove, but touchmove handle graph in devices with touch schreen
      touchmove: (e) => {
        if (draggedNode) {
          const pos = sigma.viewportToGraph(e);
          sigma.getGraph().setNodeAttribute(draggedNode, "x", pos.x);
          sigma.getGraph().setNodeAttribute(draggedNode, "y", pos.y);

          e.preventSigmaDefault();
          /*e.original.preventDefault();
          e.original.stopPropagation();*/
        }
      },
//- - - - - - - - - - - - - - PRESS INSIDE THE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      //when I press/touch the mouse inside the graph: disable autoscaling when moving a node
      mousedown: (e) => {      
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
      touchdown: (e) => {
        if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox());
      },
//- - - - - - - - - - - - - - RAISE ON THE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      //when I raise after pressing the mouse/touching the display on the graph: set draggedNode value to null (there is no more dragged node)
      mouseup: () => {      
        if (draggedNode) {    
          setDraggedNode(null);  
        }
      },
      touchup: (e) => {     
        if (draggedNode) {
          setDraggedNode(null);
        }
      },
//- - - - - - - - - - - - - - PRESS ON NODE/EDGE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      //when i press on node: select the node
      downNode: (e) => {       
        //console.log("downNode", e.node);
        passOnNode(null);   //don’t want to see the attribute when I press on the node
        pressOnNode(e.node);    //get the selected node
        setDraggedNode(e.node);   //insert node id in DraggedNode: this is my dragged node
        
        viewMenuNode(false);    //don't view the node menu, because i want to see menu only when i press with right mouse button
      },
      //when i press on edge: select the edge
      downEdge: (e) => {
        //console.log("downEdge", e.edge);
        passOnEdge(null);   //don’t want to see the attribute when I press on the edge
        pressOnEdge(e.edge);     //get the selected edge
        
        viewMenuEdge(false);    //don't view the edge menu, because i want to see menu only when i press with right mouse button
      },
//- - - - - - - - - - - - - - CURSOR ON NODE/EDGE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      //when cursor IS on node: view the node attribute 
      enterNode: (e) => {
        //console.log("enterNode", e.node);
        passOnNode(e.node);   //pass the currentNode to LoadGraph
        //pass the x and y position to LoadGraph for to set menuNode and attributeNode
        posx (e.event.x);   
        posy (e.event.y);
        
        viewMenuNode(false);    //don't view the node menu, because i want to see menu only when i press with right mouse button
      },
      //when cursor IS on edge: view the edge attribute 
      enterEdge: (e) => {
        passOnEdge(e.edge);   //pass the currentEdge to LoadGraph
        //pass the x and y position to LoadGraph for to set menuEdge and attributeEdge
        posx (e.event.x);
        posy (e.event.y);

        viewMenuEdge(false);    //don't view the edge menu, because i want to see menu only when i press with right mouse button
      },
      //when cursor ISN'T on node
      leaveNode: (e) => {
        passOnNode(null);     //pass the currentNode (NULL) to LoadGraph
        //pass the x and y position to LoadGraph for to set menuEdge and attributeEdge
        /*posx (null);
        posy (null);*/

        //viewMenuNode(false);    //don't view the node menu, because i want to see menu only when i press with right mouse button
      },
      //when cursor ISN'T on edge
      leaveEdge: (e) => {
        passOnEdge(null);   //pass the currentEdge (NULL) to LoadGraph
        //pass the x and y position to LoadGraph for to set menuEdge and attributeEdge
        // posx (null);  
        // posy (null);
        
        //viewMenuEdge(false);    //don't view the edge menu, because i want to see menu only when i press with right mouse button
      },
//- - - - - - - - - - - - - - RIGHT CLICK ON NODE/EDGE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      //press on right click on node graph: view the menu
      rightClickNode: (e) => {
        pressOnEdge(null);
        pressOnNode(e.node);
        //console.log("rightClickNode", e.node)
        const sigmaInstance = sigmaRef.current;     //access to sigma varibale
        const container = sigmaInstance.getContainer();     //get html container of istance
        const handleContextMenu = (e) => {    //manage the event: menu appearance
          e.preventDefault();   //prevent the default menu behavior of the browser
        };
        container.addEventListener("contextmenu", handleContextMenu);     //does not display the default menu browser       

        viewMenuNode(true);   //now i want to see menu node
        setDraggedNode(null);   //with right click i don't move the graph
      },
      //press on right click on edge graph
      rightClickEdge: (e) =>{
        pressOnNode(null);
        pressOnEdge(e.edge);
        //console.log("rightClickEdge", e.edge)
        const sigmaInstance = sigmaRef.current;     //access to sigma varibale
        const container = sigmaInstance.getContainer();     //get html container of istance
        const handleContextMenu = (e) => {    //manage the event: menu appearance
          e.preventDefault();   //prevent the default menu behavior of the browser
        };
        container.addEventListener("contextmenu", handleContextMenu);   //does not display the default menu browser

        viewMenuEdge(true);     //now i want to see menu edge
      },
      
    });
  }, [registerEvents, sigma, draggedNode, passOnNode, passOnEdge, posx, posy, pressOnNode, pressOnEdge, viewMenuNode, viewMenuEdge]);


  return null;
};

export default EventsGraph;