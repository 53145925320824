// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-rate {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rate-face {
    display: flex;
    justify-content: center;
}

/* test the buttons locations */
.rate-btn {
    background-color: transparent;
    border-radius: .3rem;
    padding: .8rem;
    margin: 1rem;
    font-size: 2rem;
    transition: all ease .2s;
}

.good {
    border: 1px solid #a6cdc6;
}

.average {
    border: 1px solid #fde338;
}

.bad {
    border: 1px solid #ff8000;
}

.good:hover {
    background-color: #a6cdc6;
}

.average:hover {
    background-color: #fde338;
}

.bad:hover {
    background-color: #ff8000;
}

.good:active,
.average:active,
.bad:active {
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/style/RateEdge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA,+BAA+B;AAC/B;IACI,6BAA6B;IAC7B,oBAAoB;IACpB,cAAc;IACd,YAAY;IACZ,eAAe;IACf,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;IAGI,6BAA6B;AACjC","sourcesContent":[".modal-rate {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.rate-face {\n    display: flex;\n    justify-content: center;\n}\n\n/* test the buttons locations */\n.rate-btn {\n    background-color: transparent;\n    border-radius: .3rem;\n    padding: .8rem;\n    margin: 1rem;\n    font-size: 2rem;\n    transition: all ease .2s;\n}\n\n.good {\n    border: 1px solid #a6cdc6;\n}\n\n.average {\n    border: 1px solid #fde338;\n}\n\n.bad {\n    border: 1px solid #ff8000;\n}\n\n.good:hover {\n    background-color: #a6cdc6;\n}\n\n.average:hover {\n    background-color: #fde338;\n}\n\n.bad:hover {\n    background-color: #ff8000;\n}\n\n.good:active,\n.average:active,\n.bad:active {\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
