import "@react-sigma/core/lib/react-sigma.min.css";
import { MenuItem, MenuList} from "@mui/material";
import React from "react";

function MenuEdge({viewMenuEdge, myEdgeSelected, viewAttributes, viewExtreme, viewGraph, hideEdge, myAcceptEdge, myDeclineEdge, valueOfConfidence}) {

  //MANAGING ACCEPTANCE OF A NODE ON THE BASIS OF CONFIDENCE
  const handleEdgeAccept = () => {
    if (myEdgeSelected) {   
      myAcceptEdge();
      viewMenuEdge(false);
    }
  };
  //MANAGING OF REFUSAL OF A NODE ON THE BASIS OF CONFIDENCE
  const handleEdgeDecline = () => {
    if (myEdgeSelected) {   
      myDeclineEdge();
      viewMenuEdge(false);
    }
  };

  //MANAGING THE DISPLAY OF NODE ATTRIBUTES
  const handleEdgeAttr = () => {
    if (myEdgeSelected) { 
      viewAttributes();
      viewMenuEdge(false);
    }
  }
  //MANAGING THE VISUALIZATION OF THE NEIGHBORS OF A NODE: only the nearby nodes
  const handleEdgeNeigh = () => {
    if (myEdgeSelected) { 
      viewExtreme();
      viewMenuEdge(false);
    }
  }
  //MANAGING THE VISUALIZATION OF THE NEIGHBORS OF A NODE: all nodes
  const handleEdgeGraph = () => {
    if (myEdgeSelected) { 
      viewGraph();
      viewMenuEdge(false);
    }
  }
  //MANAGING THE DELETION OF A NODE
  const handleEdgeHide = () => {
    if (myEdgeSelected) {   
        hideEdge();
        viewMenuEdge(false);
    }
  };


  const menuItems = [];

    if (valueOfConfidence !== undefined) {
        menuItems.push(
            <MenuItem key="confidence" ><b>Confidence: {valueOfConfidence}</b></MenuItem>,
            <MenuItem key="accept" onClick={handleEdgeAccept} style={{color: 'green'}}>Accept edge</MenuItem>,
            <MenuItem key="decline" onClick={handleEdgeDecline} style={{color: 'red'}}>Decline Edge</MenuItem>
        );
    }
    menuItems.push(
        <MenuItem key="view" onClick={handleEdgeAttr}>View attributes</MenuItem>,
        <MenuItem key="extreme" onClick={handleEdgeNeigh}>View extreme</MenuItem>,
        <MenuItem key="graph" onClick={handleEdgeGraph}>View graph</MenuItem>,
        
    )
    if(valueOfConfidence === undefined){
      menuItems.push(
        <MenuItem key="hide" onClick={handleEdgeHide}>Hide edge</MenuItem>
      )
    }

    return (
    <MenuList>
        {menuItems}
    </MenuList>
    );

}

export default MenuEdge;