// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #a6cdc6;
}

.login-container {
    background-color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1.8rem 1rem 1rem 1.5rem;
    margin: 7% auto 1rem auto;
    border: 3px solid #fff;
    border-radius: .8rem;
}

h4 {
    color: gray;
    margin: 0 0 1.5rem 0;
    text-align: center;
    padding-bottom: .7rem;
}

.login-container .form-control {
    margin: 1rem 0;
    width: 16rem;
    height: 1rem;
    border: 2px solid #a6cdc6;
}

.login-container label {
    opacity: .5;
}

.login-container .btn {
    width: 100%;
    margin-top: .5rem;
}

.login-warning {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.fade-in {
    opacity: 1;
    transition: all ease-in .9s;
}

.fade-out {
    transition: all ease-in .9s;
}`, "",{"version":3,"sources":["webpack://./src/style/Login.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,uBAAkB;IAAlB,kBAAkB;IAClB,wBAAmB;IAAnB,mBAAmB;IACnB,gCAAgC;IAChC,yBAAyB;IACzB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,uBAAkB;IAAlB,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":["body {\n    background-color: #a6cdc6;\n}\n\n.login-container {\n    background-color: #fff;\n    width: fit-content;\n    height: fit-content;\n    padding: 1.8rem 1rem 1rem 1.5rem;\n    margin: 7% auto 1rem auto;\n    border: 3px solid #fff;\n    border-radius: .8rem;\n}\n\nh4 {\n    color: gray;\n    margin: 0 0 1.5rem 0;\n    text-align: center;\n    padding-bottom: .7rem;\n}\n\n.login-container .form-control {\n    margin: 1rem 0;\n    width: 16rem;\n    height: 1rem;\n    border: 2px solid #a6cdc6;\n}\n\n.login-container label {\n    opacity: .5;\n}\n\n.login-container .btn {\n    width: 100%;\n    margin-top: .5rem;\n}\n\n.login-warning {\n    width: fit-content;\n    margin: 0 auto;\n}\n\n.fade-in {\n    opacity: 1;\n    transition: all ease-in .9s;\n}\n\n.fade-out {\n    transition: all ease-in .9s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
