// MenuGraph.js
import React, {useState} from "react";
import "../style/MenuGraph.css"   

const MenuGraph = ({ isOpen, onClose, menuItems, mySelectedMenuNode, mySelectedMenuEdge, setMySelectedMenuNode, setMySelectedMenuEdge, myMenuGraph, setMyMenuGraph }) => {

  const [showNodes, setShowNodes] = useState(true);   //control variable to show neighbors or complete graph
  const [showEdges, setShowEdges] = useState(true);   //control variable to show extreme or complete graph

//- - - - - - - - - - - - - - - - -HANDLE NEIGHBORS- - - - - - - - - - - - - - - - - - - - - - - - - - -  
  const handleNeighbors = () => {
    const updatedGraph = myMenuGraph.copy(); //copy of current graph
    if (mySelectedMenuNode) {
      setShowNodes(false);    //change the labelButton
      const neighbors = myMenuGraph.neighbors(mySelectedMenuNode);    //get the neighbors node
      updatedGraph.forEachNode((node, attributes) => {
        //set visible only the selected node and its neighbors
        attributes.hidden = mySelectedMenuNode !== node && !neighbors.includes(node);
      });
    } else if (mySelectedMenuEdge){
      setShowEdges(false);    //change the labelButton
      updatedGraph.forEachNode((node, attributes) => {
        //set visible only the extreme node of edge selected (and obviously the edge)
        const isNodeInSelectedEdge = node === updatedGraph.source(mySelectedMenuEdge) || node === updatedGraph.target(mySelectedMenuEdge);
        attributes.hidden = !isNodeInSelectedEdge;
      });
    }
    setMyMenuGraph(updatedGraph);
  }
  
//- - - - - - - - - - - - - - - - -HANDLE GRAPH- - - - - - - - - - - - - - - - - - - - - - - - - - -  
  const handleGraph = () =>{
    setShowNodes(true);     //change the labelButton
    setShowEdges(true);     //change the labelButton
    if (myMenuGraph) {
      const updatedGraph = myMenuGraph.copy(); //copy of graph
      updatedGraph.forEachNode((node, attributes) => {
        //sets the hidden attribute to false for all nodes that are not close
        attributes.hidden = false;
      });
      setMyMenuGraph(updatedGraph);
    }
  }

//- - - - - - - - - - - - - - - - -HANDLE HIDE- - - - - - - - - - - - - - - - - - - - - - - - - - -  
  const handleHide = () =>{
    const updatedGraph = myMenuGraph.copy();        //copy of graph
    if (mySelectedMenuNode) {     
      updatedGraph.dropNode(mySelectedMenuNode);      //delete selected node from updateGraph
      setMySelectedMenuNode(null);        //anyone node selected
    }else if (mySelectedMenuEdge) {     
      if (updatedGraph.getEdgeAttribute(mySelectedMenuEdge,'type') === 'line') {
        const sourceNode = updatedGraph.source(mySelectedMenuEdge);
        const targetNode = updatedGraph.target(mySelectedMenuEdge);
        updatedGraph.dropEdge(mySelectedMenuEdge);
        const newEdge = updatedGraph.edge(targetNode, sourceNode);
        updatedGraph.dropEdge(newEdge);
      } else {
        updatedGraph.dropEdge(mySelectedMenuEdge);
      }
      setMySelectedMenuEdge(null);
    }
    setMyMenuGraph(updatedGraph);       //refresh graph
    onClose();    //close menu attribute
  }

  return (
    
    <div className={`menu-node ${isOpen ? "open" : ""}`}>
      
      {/*SELECTED NODE*/}
      {mySelectedMenuNode && (
        <>
          <h3 style={{ margin: 0, padding: 0 }}>Node details</h3>
          {Object.entries(menuItems).map(([key, value]) => (
            <p key={key}>
              {key === 'name' ? (
                <>
                  <strong>{key}</strong>
                  <br />
                  <a href={value.replace(/[<>]/g, '')}>{value.replace(/[<>]/g, '')}</a>
                </>
              ) : key === 'family' ? (  // Qui cambia "family" con "Type"
                <>
                  <strong>Type</strong>
                  <br />
                  {value}
                </>
              ): key !== 'x' && key !== 'y' && key !== 'size' && key !== 'color' && key !== 'hidden' ? (
                <>
                  <strong>{key}</strong>
                  <br />
                  {value}
                </>
              ) : null}
            </p>
          ))}
          <button className="closeButton" onClick={onClose}>
            X
          </button>
          {showNodes ? (
            <button className="showNodes" onClick={handleNeighbors}>
              <span>Show neighbors</span>
            </button>
          ) : (
            <button className="showNodes" onClick={handleGraph}>
              <span>Show graph</span>
            </button>
          )}
          <button className="hideNode" onClick={handleHide}>
            <span>Hide node</span>
          </button>
        </>
      )}
{/* SELECTED EDGE */}
{mySelectedMenuEdge && (
  <>
    <h3 style={{ margin: 0, padding: 0 }}>Edge details</h3><br />
    <>
      <strong>Source:</strong>
      <br />
      {myMenuGraph.getNodeAttribute(myMenuGraph.source(mySelectedMenuEdge), 'label')}
    </>
    <br /><br />
    <>
      <strong>Target:</strong>
      <br />
      {myMenuGraph.getNodeAttribute(myMenuGraph.target(mySelectedMenuEdge), 'label')}
    </>
    {Object.entries(menuItems).map(([key, value]) => (
      <p key={key}>
        {key === 'Tipology' ? (  // Sostituisci 'Tipology' con 'Type'
          <>
            <strong>Type</strong>
            <br />
            {value}
          </>
        ) : key !== 'x' && key !== 'y' && key !== 'size' && key !== 'color' && key !== 'hidden' && 
           key !== 'parallelMinIndex' && key !== 'declined' && key !== 'accept' && key !== 'type' && 
           key !== 'parallelIndex' && key !== 'parallelMaxIndex' && key !== 'curvature' ? ( // Rimuovi 'type' e 'declined'
          <>
            <strong>{key}</strong>
            <br />
            {value}
          </>
        ) : null}
      </p>
    ))}
    <button className="closeButton" onClick={onClose}>
      x
    </button>
    {showEdges ? (
      <button className="showNodes" onClick={handleNeighbors}>
        <span>Show extremes</span>
      </button>
    ) : (
      <button className="showNodes" onClick={handleGraph}>
        <span>Show graph</span>
      </button>
    )}
    {myMenuGraph.getEdgeAttribute(mySelectedMenuEdge, '_confidence') === undefined ? (
      <button className="hideNode" onClick={handleHide}>
        <span>Hide edge</span>
      </button>
    ) : (
      console.log("Errore")
    )}
  </>
)}

     
          
    </div>
  );
};

export default MenuGraph;