import { Alert } from "bootstrap";
import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../style/Login.css';

function Login () {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [viewWarning, setViewWarning] = useState(false);

    function handleSubmit (event) {
        event.preventDefault();
        if (email && password) {
            navigate("/homePage", {state: email});
        }
        else {
            setViewWarning(true)
        }
    };

    return (
        <>
            <form className="login-container" onSubmit={handleSubmit}>
                <h4>Login to Bio-Viber</h4>
                <div className="form-floating mb-3">
                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" onChange={(e) => { setEmail(e.target.value) }}/>
                    <label for="floatingInput">Username or email address</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }}/>
                    <label for="floatingPassword">Password</label>
                </div>
                <button className="btn btn-success mb-3" type="submit" style={{textDecoration: "none", color: "white"}}>Submit</button>
            </form>
            {
                viewWarning &&
                <div class={viewWarning ? "alert alert-warning login-warning fade-in" : "alert alert-warning login-warning fade-out"} role="alert">
                    Please complete the form!
                </div>
            }
        </>
    );
}

export default Login;