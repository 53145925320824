import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import DisplayGraph from './component/DisplayGraph';
import Login from './component/Login';
import HomePage from './component/HomePage';
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to="/login" replace />}/>
        <Route path='/login' element={<Login />} />
        <Route path='/homePage' element={<HomePage />}/>
        <Route path='/displayGraph' element={<DisplayGraph />}/>
      </Routes>
    </Router>
  );
}

export default App;
