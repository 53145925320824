// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truncate-text {
    overflow-y: auto; /* Abilita lo scorrimento verticale */
    font-size:smaller;
    max-height: 100%; /* Imposta l'altezza massima per la cella */
    display: block; /* Assicurati che l'overflow funzioni correttamente */
    word-wrap: break-word; /* Permetti il wrapping delle parole */
    padding-right: 5px; /* Aggiungi un padding per evitare la sovrapposizione della scrollbar */
  }
  
  /* Add a bottom border to each row */
.separated-row {
  border-bottom: 1px solid #ddd; /* Change the color as needed */
  padding: 10px 15px;
}

/* Optionally, you can also add padding to each cell to increase spacing */
.separated-row > th, .separated-row > td {
  padding: 10px 15px; /* Adjust the padding as needed */
}


.no-underline {
  text-decoration: none;
}
  
.center-text {
  text-align: center;
}

.wide-column {
  width: 100%; /* Imposta la larghezza della seconda colonna */
}

.narrow-column {
  width: 10%; /* Imposta una larghezza ridotta per le altre colonne */
}


.large-checkbox {
  width: 20px; /* Imposta la larghezza desiderata */
  height: 20px; /* Imposta l'altezza desiderata */
  transform: scale(1.5); /* Scala la dimensione della checkbox */
}
  `, "",{"version":3,"sources":["webpack://./src/style/Modal.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,qCAAqC;IACvD,iBAAiB;IACjB,gBAAgB,EAAE,2CAA2C;IAC7D,cAAc,EAAE,qDAAqD;IACrE,qBAAqB,EAAE,sCAAsC;IAC7D,kBAAkB,EAAE,uEAAuE;EAC7F;;EAEA,oCAAoC;AACtC;EACE,6BAA6B,EAAE,+BAA+B;EAC9D,kBAAkB;AACpB;;AAEA,0EAA0E;AAC1E;EACE,kBAAkB,EAAE,iCAAiC;AACvD;;;AAGA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,+CAA+C;AAC9D;;AAEA;EACE,UAAU,EAAE,uDAAuD;AACrE;;;AAGA;EACE,WAAW,EAAE,oCAAoC;EACjD,YAAY,EAAE,iCAAiC;EAC/C,qBAAqB,EAAE,uCAAuC;AAChE","sourcesContent":[".truncate-text {\r\n    overflow-y: auto; /* Abilita lo scorrimento verticale */\r\n    font-size:smaller;\r\n    max-height: 100%; /* Imposta l'altezza massima per la cella */\r\n    display: block; /* Assicurati che l'overflow funzioni correttamente */\r\n    word-wrap: break-word; /* Permetti il wrapping delle parole */\r\n    padding-right: 5px; /* Aggiungi un padding per evitare la sovrapposizione della scrollbar */\r\n  }\r\n  \r\n  /* Add a bottom border to each row */\r\n.separated-row {\r\n  border-bottom: 1px solid #ddd; /* Change the color as needed */\r\n  padding: 10px 15px;\r\n}\r\n\r\n/* Optionally, you can also add padding to each cell to increase spacing */\r\n.separated-row > th, .separated-row > td {\r\n  padding: 10px 15px; /* Adjust the padding as needed */\r\n}\r\n\r\n\r\n.no-underline {\r\n  text-decoration: none;\r\n}\r\n  \r\n.center-text {\r\n  text-align: center;\r\n}\r\n\r\n.wide-column {\r\n  width: 100%; /* Imposta la larghezza della seconda colonna */\r\n}\r\n\r\n.narrow-column {\r\n  width: 10%; /* Imposta una larghezza ridotta per le altre colonne */\r\n}\r\n\r\n\r\n.large-checkbox {\r\n  width: 20px; /* Imposta la larghezza desiderata */\r\n  height: 20px; /* Imposta l'altezza desiderata */\r\n  transform: scale(1.5); /* Scala la dimensione della checkbox */\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
