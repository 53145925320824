import React, { useState, useEffect } from 'react';

const CustomForm2 = ({confidenceLevelUser, labelOfMyEdges, numerEdge, acceptEdgePredicted, buttonPressed}) => {

  const [value, setValue] = useState(0.0);
  const [selectEdges, setSelectEdges] = useState({});   //object family, selected(bool)
  const [press, setPress] = useState(null);

  const handleChange = (event) => {
    setPress(null);
    setValue(parseFloat(event.target.value));
    confidenceLevelUser(value)
  };

  const handleMouseEnter = (event) => {
    event.target.style.backgroundColor = 'rgb(150, 150, 150)';
  };

  const handleMouseLeave = (event) => {
    event.target.style.backgroundColor = 'rgb(200, 200, 200)';
  };

  const acceptEdges = () => {
    setPress(true)
  };

  const declineEdges = () => {
    setPress(false)
  };

  useEffect(() => {
    buttonPressed(press)
    if (press !== null) {
      const timeoutId = setTimeout(() => {
        setPress(null);
      }, 0.1); //set the time in milliseconds after which you want to reset the press variable to null
      return () => clearTimeout(timeoutId);
    }
  },[press, buttonPressed])

  useEffect(() => {
    //initializes all checkboxes to true when the component mounts (so when I refresh)
    const initialCheckedState = {};
    // eslint-disable-next-line
    labelOfMyEdges.map((family) =>  {
      initialCheckedState[family] = {selected:true};
    });
    setSelectEdges(initialCheckedState);
  }, [labelOfMyEdges]);

  useEffect(() =>{
    acceptEdgePredicted(selectEdges);
  },[selectEdges, acceptEdgePredicted])

  return (
    <div style={styles.container}>
      
      <p style={{ textAlign:'center', marginBottom: '0', margin: 'auto' }}>Value of confidence: {value.toFixed(2)}
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={value}
        onChange={handleChange}
        style={{ width: '70%', cursor: 'grab' }}
      />
      </p>
      {labelOfMyEdges.length > 0 ? (
      <div>
        <table style={{ width: '100%', textAlign: 'center', margin: 'auto' }}>
          <thead style={{ fontSize: '80%' }}>
            <tr>
              <th style={{ borderBottom: '2px solid black' }}>Edges</th>
              <th style={{ borderBottom: '2px solid black' }}>Type of edges</th>
              <th style={{ borderBottom: '2px solid black' }}>Select</th>
            </tr>
          </thead>
          <tbody style={{ maxHeight: '10vh', overflowY: 'auto' }}>
            {labelOfMyEdges.map((family, index) => (
              <tr key={index}>
                <td style={{ fontSize: '70%' }}>
                  <strong>{numerEdge[family]}</strong>
                </td>
                <td style={{ fontSize: '70%' }}>
                  <strong>{family}</strong>
                </td>
                <td>
                  <input
                    type="checkbox"
                    id={family}
                    style={{
                      cursor: 'pointer',
                      transform: 'scale(1.5)',
                    }}
                    onChange={() => {
                      setSelectEdges((prevViewEdges) => ({
                        ...prevViewEdges,
                        [family]: {
                          ...prevViewEdges[family],
                          selected: !prevViewEdges[family].selected,
                        },
                      }));
                    }}
                    checked={selectEdges[family]?.selected || false}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <br />

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button
            style={{
              flex: '1',
              padding: '3vh',
              font: 'bold',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: 'rgb(200, 200, 200)',
              color: 'green',
              border: '1px solid green',
              transition: 'background-color 0.3s',
              marginRight: '2%', // Aggiunto margine a destra
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={acceptEdges}
          >
            <span>Accept</span>
          </button>
          <button
            style={{
              flex: '1',
              padding: '3vh',
              font: 'bold',
              borderRadius: '4px',
              cursor: 'pointer',
              backgroundColor: 'rgb(200, 200, 200)',
              color: 'red',
              border: '1px solid red',
              transition: 'background-color 0.3s',
              marginLeft: '5px', // Aggiunto margine a sinistra
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={declineEdges}
          >
            <span>Decline</span>
          </button>
        </div>
        <span style={styles.subtitiles}>select the edges level you want to view and decide which type of edge gave or take.</span>
      </div>
      
    ) : (
      <p>There are no edges to accept.</p>
    )}
      
    </div>
  );
};


const styles = {
  container: {
    margin: 'auto',
    backgroundColor: "white",
    paddingTop: 0,
    paddingLeft: 7,
    paddingRIght: 7,
    paddingBottom: 5,
    textAlign: 'center',
  },
  subtitiles: {
    fontSize: '70%',
    color: "black",
  },
};  

export default CustomForm2;