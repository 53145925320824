// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  position: fixed;
  /* top: 2px; */
  right: -30%;
  /*from start going out of schreen*/
  /* height: 99.3%; */
  height: 100%;
  width: 20%;
  background-color: #fff;
  border: 1px solid rgba(94, 91, 91, 0.719);
  transition: right 0.3s ease-in-out;
  z-index: 100;
  padding: 3vh 1vh;
  text-align: left;
  /* border-radius: 10px; */
}


.menu.open {
  right: 1px;
  /* brings the menu into the screen when it is open */
}


.btn-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  font-size: 1em;
}


.menuItemButton {
  width: 100%;
  border-top: 1px solid gray;
  border-bottom: 1px solid white;
  border-left: none;
  border-right: none;
  background-color: transparent;
  margin: 0;
  width: 100%;
  padding: 2vh;
  cursor: pointer;
  text-align: left;
  font-weight: bold;
}

.menu h3 {
  margin-left: 5%;
  margin-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/style/MenuOptions.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,cAAc;EACd,WAAW;EACX,kCAAkC;EAClC,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,sBAAsB;EACtB,yCAAyC;EACzC,kCAAkC;EAClC,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B;;;AAGA;EACE,UAAU;EACV,oDAAoD;AACtD;;;AAGA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,cAAc;AAChB;;;AAGA;EACE,WAAW;EACX,0BAA0B;EAC1B,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;EAClB,6BAA6B;EAC7B,SAAS;EACT,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,aAAa;AACf","sourcesContent":[".menu {\r\n  position: fixed;\r\n  /* top: 2px; */\r\n  right: -30%;\r\n  /*from start going out of schreen*/\r\n  /* height: 99.3%; */\r\n  height: 100%;\r\n  width: 20%;\r\n  background-color: #fff;\r\n  border: 1px solid rgba(94, 91, 91, 0.719);\r\n  transition: right 0.3s ease-in-out;\r\n  z-index: 100;\r\n  padding: 3vh 1vh;\r\n  text-align: left;\r\n  /* border-radius: 10px; */\r\n}\r\n\r\n\r\n.menu.open {\r\n  right: 1px;\r\n  /* brings the menu into the screen when it is open */\r\n}\r\n\r\n\r\n.btn-close {\r\n  position: absolute;\r\n  top: 2rem;\r\n  right: 2rem;\r\n  cursor: pointer;\r\n  font-size: 1em;\r\n}\r\n\r\n\r\n.menuItemButton {\r\n  width: 100%;\r\n  border-top: 1px solid gray;\r\n  border-bottom: 1px solid white;\r\n  border-left: none;\r\n  border-right: none;\r\n  background-color: transparent;\r\n  margin: 0;\r\n  width: 100%;\r\n  padding: 2vh;\r\n  cursor: pointer;\r\n  text-align: left;\r\n  font-weight: bold;\r\n}\r\n\r\n.menu h3 {\r\n  margin-left: 5%;\r\n  margin-top: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
