import React,{useEffect, useState} from "react";
import { SigmaContainer } from "@react-sigma/core";
import "@react-sigma/core/lib/react-sigma.min.css";
import { IoMdSettings } from "react-icons/io";
import { FaPaperPlane, FaRedo } from "react-icons/fa";     //FaArrowAltCircleDown, FaArrowRight, FaArrowDown 
import { EdgeLineProgram,EdgeArrowProgram } from "sigma/rendering";
import { EdgeCurvedArrowProgram } from "@sigma/edge-curve";   //EdgeCurveProgram, 
//import { Tabs, Tab, Box, Typography } from '@mui/material';
import { saveAs } from 'file-saver';
import { MultiGraph } from "graphology";
import * as d3 from 'd3';
import '../style/index.css';
import LoadGraph from "./LoadGraph";
import MenuGraph from "./MenuGraph";
import MenuOptions from "./MenuOptions";
import CustomForm1 from "./CustomForm1";
import CustomForm2 from "./CustomForm2";
import CustomForm3 from "./CustomForm3";
import CustomForm4 from "./CustomForm4";
import ModalArticles from "./ModalArticles";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


function DisplayGraph() {
  const [updateData, updateNewData] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [lastModifiedVariable, setLastModifiedVariable] = useState(null);   //determine if an arc or node was passed last 
  const [graph, setGraph] = useState(new MultiGraph());
  const [familyColor, setFamilyColor] = useState({});   //allows you to change the color of nodes based on the family in a real time
  const [familyLabel, setFamilyLabel] = useState({});
  const [familyConfidence, setFamilyConfidence] = useState({});
  const [numberFamilyConfidence, setNumberFamilyConfidence] = useState({});
  const [viewFamily, setViewFamily] = useState({});   //allows you to view nodes of selected families real-time
  const [viewEdges, setViewEdges] = useState({});   //allows you to view edges of selected families real-time
  const [acceptConfidentEdges, setAcceptConfidentEdges] = useState({});
  const [confidenceLevel, setConfidenceLevel] = useState(0.0);
  const [pressButton, setPressButton] = useState(null);
  const [isMenuOptionsOpen, setMenuOptionsOpen] = useState(false);    //handle menu options open/close
  const [isMenuGraphOpen, setMenuGraphOpen] = useState(false);        //handle menu graph open/close
  const [informationGraph, setInformationGraph] = useState({});     //information of graph
  const [confidenceEdges, setConfidenceEdges] = useState(0);     //information of edges
  const [markedEdges, setMarkedEdges] = useState(0);     //information of edges
  const legendValues = [0, 0.2, 0.4, 0.6, 0.8, 1];
  const [selectedLayout, setSelectedLayout] = useState("normal");
  const [selectedNodeSize, setSelectedNodeSize] = useState(10);
  const [selectedEdgeSize, setSelectedEdgeSize] = useState(3);
  const [viewEdgesForFile, setViewEdgesForFile] = useState([]);
  const [viewEdgesNoConfForFile, setViewEdgesNoConfForFile] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [abstractData, setAbstractData] = useState([]);
  const [enterButton, setEnterButton] = useState(true);
  
  //assign the color attribute to the arc based on the confidence value provided, is utilizated for legend
  const getConfidenceColor = (confidence) => {
    //define a color scale by d3
    const colorScale = d3.scaleLinear()
      .domain([0, 1])  //range: min size e max size
      .range(['red', 'blue']);    //bottom and top color of scale
    return colorScale(confidence);
  };

  const handleLayoutChange = () => {
    setSelectedLayout(prevLayout => prevLayout === "fa2" ? "normal" : "fa2");
};

  //determine if an arc or node was passed last (it's required for menù attribute) 
  useEffect(()=>{
    if (selectedNode !== null && selectedNode !== lastModifiedVariable) {
      setLastModifiedVariable(selectedNode);
      setSelectedEdge(null)
    } else if (selectedEdge !== null && selectedEdge !== lastModifiedVariable) {
      setLastModifiedVariable(selectedEdge);
      setSelectedNode(null)
    }
  },[selectedNode, selectedEdge, lastModifiedVariable])

 //- - - - - - - - - - - - - - INPUTTEXT parameters - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleInputChange = (event) => {
    //do somethings with input text value
    setInputValue(event.target.value);
  };

  const handleEnterClick = async () => {
    const data = await getAbstract();
    setAbstractData(data);
    if (enterButton === true){
      setEnterButton(false)
    }else{
      setEnterButton(true)
    }
  };

  const refreshPage = async () => {
    window.location.reload();
  };

  const getAbstract = async () => {
    const query = inputValue.split(' ').join(' AND ');
    try {
      const response = await fetch(`http://fievel.anacleto.di.unimi.it:5011/pubmed_gathering?query="${query}"&max=10`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      const data = "Error";
      console.log(error.message);
      return data
    }
  };


 //- - - - - - - - - - - - - - SAVE EDGES - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
 const handleButtonClick = async () => {
  try {
    const acceptedEdges = [];
    let edgesAsString = '';  // Declare with let so it can be reassigned
    // console.log("viewEdgesForFile", viewEdgesForFile);
    // console.log("viewEdgesNoConfForFile",viewEdgesNoConfForFile);
    viewEdgesForFile.forEach((edgeWithConfidence) => {
      console.log(edgeWithConfidence);
      viewEdgesNoConfForFile.forEach((edge) => {
        if (edgeWithConfidence.identity.toString() === edge.identity) {
          if (edge.properties.accept === true) {
            acceptedEdges.push(edgeWithConfidence);
          }
        }
      });
    });

    // Construct edgesAsString from acceptedEdges
    edgesAsString = "[" + acceptedEdges.map(edge => JSON.stringify(edge)).join(',') + "]";

    const response = await fetch('http://fievel.anacleto.di.unimi.it:5011/store_graph', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: edgesAsString,
    });

    
    // const file = new Blob([edgesAsString], { type: 'text/plain;charset=utf-8' });  // Create file from edgesAsString
    // console.log(edgesAsString);
    // saveAs(file, 'accept_edges.json');  // Save file

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Success:', data);
  } catch (error) {
    console.log("Problem:" + error);
  }

};



   //- - - - - - - - - - - - - - SAVE EDGES - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
   const handleButtonClickEnhance = async () => {
    try {
      const acceptedEdges = [];
      let edgesAsString = '';  // Declare with let so it can be reassigned
      // console.log("viewEdgesForFile", viewEdgesForFile);
      // console.log("viewEdgesNoConfForFile",viewEdgesNoConfForFile);
      viewEdgesForFile.forEach((edgeWithConfidence) => {
        console.log(edgeWithConfidence);
        viewEdgesNoConfForFile.forEach((edge) => {
          if (edgeWithConfidence.identity.toString() === edge.identity) {
            if (edge.properties.accept === true || edge.properties.declined === true) {
              if(edge.properties.declined === true){
                edgeWithConfidence.properties.declined = true;
                console.log("trovato declinato", edge);
              }
              acceptedEdges.push(edgeWithConfidence);
            }
          }
        });
      });
  
      // Construct edgesAsString from acceptedEdges
      edgesAsString = "[" + acceptedEdges.map(edge => JSON.stringify(edge)).join(',') + "]";
  
      const response = await fetch('http://fievel.anacleto.di.unimi.it:5011/enhance_schema', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: edgesAsString,
      });
  
      
      // const file = new Blob([edgesAsString], { type: 'text/plain;charset=utf-8' });  // Create file from edgesAsString
      // console.log(edgesAsString);
      // saveAs(file, 'action_edges.json');  // Save file
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.log("Problem:" + error);
    }
  };
  
  

 //- - - - - - - - - - - - - - MENUOPTIONS parameters - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const toggleMenuOptions = () => {
    setMenuOptionsOpen(!isMenuOptionsOpen);
  };

  const menuItems = [
    {
      id: 'family',
      label: 'Node Types',
      content: <CustomForm1 colorOfMyFamilys={familyColor} onColorOfMyFamilysChange={setFamilyColor} choiceViewFamily={setViewFamily} />,    //I pass the family-color object and the function to modify the family-color object: through onColorOfMyFamilysChange I can take back the color of the family modified in CustomForm1. Also pass the object {family: see/don’t see}
    }, 
    {
      id: 'confidence',
      label: 'Confidence of Edges',
      content: <CustomForm2 confidenceLevelUser={setConfidenceLevel} labelOfMyEdges={familyConfidence} numerEdge={numberFamilyConfidence} acceptEdgePredicted={setAcceptConfidentEdges} buttonPressed={setPressButton} />,      //get the value of confidence set by user
    },
    {
      id: 'edges',
      label: 'Edge Types',
      content: <CustomForm3 labelOfMyEdges={familyLabel} viewEdgesPrediction={setViewEdges} />,    
    },
    {
      id: 'sizes',
      label: 'Size of nodes and edges',
      content: <CustomForm4 onChangeNodeSize={setSelectedNodeSize} onChangeEdgeSize={setSelectedEdgeSize} />,    
    },
  ];

  const prova = (newData) => {
    if (newData === "Error"){
      console.log("ERROR");
    }
    updateNewData(newData);
    // Esempio di aggiornamento dello stato con i nuovi dati
    // setDati(newData);
  };


  

  const isHead = isMenuOptionsOpen && isMenuGraphOpen;
    return (
      <div>
      {abstractData && inputValue && <ModalArticles data={abstractData} input={inputValue} dataUpdate={prova} />}
      <div className={`ContainerPersonalized ${isMenuOptionsOpen ? "menu-options-open" : ""} ${isMenuGraphOpen ? "menu-graph-open" : ""}`}>
        <div className="backGround">
  
          <button className="menuOptionsButton" onClick={toggleMenuOptions}>
            <IoMdSettings /> Options 
          </button>
          <MenuOptions
            isOpen={isMenuOptionsOpen}    //menu should be open
            onClose={() => setMenuOptionsOpen(false)}     //menu should be closer
            menuItems={menuItems}
            onItemClick={setMenuOptionsOpen}
          />
  
              
          {lastModifiedVariable === selectedNode && selectedNode && isMenuGraphOpen && (
              <MenuGraph
                isOpen={true}
                onClose={() => setMenuGraphOpen(false)}
                menuItems={
                  selectedNode == null
                    ? setMenuGraphOpen(false)
                    : graph.getNodeAttributes(selectedNode)
                }
                mySelectedMenuNode={selectedNode}
                setMySelectedMenuNode={setSelectedNode}
                myMenuGraph={graph}
                setMyMenuGraph={setGraph}
              />
            )}
  
            {lastModifiedVariable === selectedEdge && selectedEdge && isMenuGraphOpen && (
              <MenuGraph
                isOpen={true}
                onClose={() => setMenuGraphOpen(false)}
                menuItems={
                  selectedEdge == null
                    ? setMenuGraphOpen(false)
                    : graph.getEdgeAttributes(selectedEdge)
                }
                mySelectedMenuEdge={selectedEdge}
                setMySelectedMenuEdge={setSelectedEdge}
                myMenuGraph={graph}
                setMyMenuGraph={setGraph}
              />
            )}
  
          
          <div className="contentContainer">
            <span className="title" >Bio-Viber</span>
            <p className="titleUnder"><b>Virtual Intelligent Biomedical Relation Extraction Assistant</b></p>
          </div>
  

          {/*<div className="inputContainer">
            
            <input
              id="inputGPT"
              type="text"
              className="inputText"
              placeholder="✏️  Create your graph with a word..."
              value={inputValue}
              onChange={handleInputChange}
            />
            <button className="enterButton" data-bs-toggle="modal" data-bs-target="#modalArticles" onClick={handleEnterClick} disabled={!inputValue.trim()}> 
                <span><FaPaperPlane /></span>
              </button>
          </div>*/}
  
            {enterButton ? 
              <div className="inputContainer">
            
                <input
                  id="inputGPT"
                  type="text"
                  className="inputText"
                  placeholder="✏️ Google PubMed and select an article"
                  value={inputValue}
                  onChange={handleInputChange}
                />
                <button className="enterButton" data-bs-toggle="modal" data-bs-target="#modalArticles" onClick={handleEnterClick} disabled={!inputValue.trim()}> 
                    <span><FaPaperPlane /></span>
                  </button>
              </div>
            : 
              <div className="inputContainer">
              
                <input
                  id="inputGPT"
                  type="text"
                  className="inputText"
                  placeholder="Press the reload button before making a new request."
                  value={"Press the button before making a new request"}
                  readOnly
                />
                <button className="enterButton" onClick={refreshPage}> 
                  <span><FaRedo /></span>
                </button>
              </div>
              
            }
  
          
          <span className={`underTitle ${isHead ? 'head' : 'whole'}`}>
              <span>Graph statistics: </span>
              {Object.keys(informationGraph).map((key, index, array) => (
                `${informationGraph[key]} ${key}${index !== array.length - 1 ? ', ' : ''}`
              ))}
              {isHead ? (
                <><span>.</span><br></br>
                <span>There are {Math.max(0, confidenceEdges)} edge(s) are assigned a confidence value, with {Math.max(0, confidenceEdges - markedEdges)} marked for review.</span></>
              ) : (
                <span>. There are {Math.max(0, confidenceEdges)} edge(s) are assigned a confidence value, with {Math.max(0, confidenceEdges - markedEdges)} marked for review.</span>
              )}
              
            </span>
         
          <div className="containerGraph">
            <SigmaContainer 
              style={{ flex: 1, marginTop: '10vh' }} 
              render="canvas" 
              settings={{
                renderNodeHidden: (node) => node.hidden === true, 
                labelSizeRatio: 0.2,
                renderEdgeLabels: true, 
                labelThreshold: 0, 
                defaultEdgeType: "straight",
                allowInvalidContainer: true,
                enableEdgeEvents: true,
                enableNodeEvents: true,
                enableEdgeHovering: true,
                enableNodeHovering: true,
                EdgeCurvedArrowProgram: true,
                edgeProgramClasses: {
                  line: EdgeLineProgram,
                  curved: EdgeCurvedArrowProgram,
                  straight: EdgeArrowProgram,
                },
                //defaultNodeType: "image",
                /*nodeProgramClasses: {
                  image: NodeImageProgram,
                },*/
                /*edgeLabelSize: 50,
                defaultLabelAlignment: 'left',
                labelVisibility: (node, context, settings) => {
                  return context.graph.nodes().length <= 5;
                },*/
                //labelPosition: 'bottom'
              }}>
              <LoadGraph confidenceEdges={setConfidenceEdges} markedMyEdges={setMarkedEdges} myDisplayGraph={graph} mySetDisplayGraph={setGraph} graphInfo={setInformationGraph} viewMenuAttribute={setMenuGraphOpen} mySelectedNodeDisplay={setSelectedNode} mySelectedEdgeDisplay={setSelectedEdge} colorOfMyFamilys={familyColor} changeColorOfMyFamilys={setFamilyColor} viewNodesFamily={viewFamily} confidenceLevel={confidenceLevel} labelOfMyEdge={setFamilyLabel} familyWithConfidence={setFamilyConfidence} viewEdgeFamily={viewEdges} acceptEdgeFamily={acceptConfidentEdges} pressButtonEdge={pressButton} numerOfEdgeFamily={setNumberFamilyConfidence} fa2Layout={selectedLayout} nodeSize={selectedNodeSize} edgeSize = {selectedEdgeSize} edgeForDisplay={setViewEdgesForFile} allEdgeToPass={setViewEdgesNoConfForFile} dataForGraph={updateData} />          
            </SigmaContainer>
  
            {/*<div style={{position: 'absolute', top: -10, right: 5, width: '25%', padding: '1em'}}>
                <button style={{background: selectedLayout === "fa2" ? "white" : "rgba(94, 91, 91, 0.719)", opacity: 0.9, border: selectedLayout === "fa2" ? "1px solid rgba(94, 91, 91, 0.719)" : "none", color: selectedLayout === "fa2" ? "rgba(94, 91, 91, 0.719)" : "white", fontSize: '80%', width: '49%', right: 0, padding: '1em', position: 'absolute', cursor: 'pointer', borderRadius: '5px', transition: 'all 0.3s ease'}} onClick={() => handleLayoutChange()} >{selectedLayout === "fa2" ? "Default" : "Force Atlas 2"}</button>  
            </div>*/}
  
            
            <div style={{ position: 'absolute', bottom: 10, right: 5, padding: '1%', background: 'white', border: '1px solid #ccc', textAlign: 'center', borderRadius: '1vh', boxShadow: '0 5px 4px rgba(0, 0, 0, 0.2)', zIndex: 1000, opacity: 0.6, pointerEvents: 'none' }}>
            {<span>confidence edges color</span>}
              <table>
                <tbody>
                  <tr>
                    {legendValues.map((value, index) => (
                      <td key={index} style={{ backgroundColor: getConfidenceColor(value), width: '40px', textAlign: 'center', color: 'white' }}>
                        {value}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
  
          </div>
          
          <div className="container py-5 mx-5">
            <div className="row mx-5 px-5">
              <div className = "col-6">
              <h2 className="fw-semibold fs-5">Do you want to save accepted edges?</h2>
                <button
                  className="btn btn-success p-3 fw-semibold"
                  onClick={handleButtonClick}
                >
                  Save marked edges
                </button>
              </div>

              <div className="col-6 text-end">
              <h2 className="fw-semibold fs-5">Do you want to enhance the schema?</h2>
                <button
                  className="btn btn-warning p-3 fw-semibold"
                  onClick={handleButtonClickEnhance}
                >
                  Enhance schema
                </button>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      </div>
    );
  
}

export default DisplayGraph;

