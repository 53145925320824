import React from "react";
import { useEffect,useState } from "react";

const CustomForm1 = ({colorOfMyFamilys, onColorOfMyFamilysChange, choiceViewFamily}) => {

  const [myError, setError] = useState(false);
  const [isChecked, setIsChecked] = useState({});   //object contain the family that user want to see and not see

  useEffect(() => {
    //initializes all checkboxes to true when the component mounts (so when I refresh)
    if (!colorOfMyFamilys || !colorOfMyFamilys.family) {
      console.log("ERORE",myError);  
    } else {
      setError(true);
      const initialCheckedState = {};
      Object.keys(colorOfMyFamilys.family).forEach((family) => {
        initialCheckedState[family] = true;
      });
      setIsChecked(initialCheckedState);
    }
  }, [colorOfMyFamilys.family]);

  //when I edit the families I want to view and the ones I don’t want to view
  const handleCheckboxChange = (family, newCheckedState) => {
    isChecked[family] = newCheckedState;
    choiceViewFamily(isChecked)
  };
  
  //when modify the color in input of the family
  const handleColorChange = (family, newColor) => {
    const updateColor = { ...colorOfMyFamilys};    //create new object
    updateColor.family[family] = newColor;    //modify family color
    onColorOfMyFamilysChange(updateColor);    //set in DisplayGraph the new object containing the new family color
  };
  

  return (
    <div style={styles.container}>
      {myError === false ? (
        <p>There are no nodes to view.</p>
      ) : (
        <div style={{ maxHeight: '15vh', overflowY: 'auto' }}>
          <table style={{ width: '70%', textAlign: 'center', margin: 'auto' }}>
            <thead style={{ fontSize: '80%' }}>
              <tr>
                <th style={{ borderBottom: '2px solid black' }}>Color</th>
                <th style={{ borderBottom: '2px solid black' }}>Type</th>
                <th style={{ borderBottom: '2px solid black' }}>View</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(colorOfMyFamilys.family).map(([family, color]) => (
                <tr key={family}>
                  <td>
                    {/* CHOOSE COLOR FAMILY */}
                    <input 
                      type="color" 
                      style={{
                        border: 'none',
                        width: '50%',
                      }}
                      value={color}  // original color
                      onChange={(e) => handleColorChange(family, e.target.value)}  
                    />
                  </td>
                  <td>
                    {/* NAME FAMILY */}
                    <strong style={{ fontSize: '80%' }}> {family}</strong> 
                  </td>
                  <td>
                    {/* CHOOSE WHICH FAMILY VIEW */}
                    <input
                      type="checkbox"
                      id={family}
                      style={{
                        marginRight: '2%',   // margin right
                        cursor: 'pointer',     // change the cursor on moveover checkbox
                        transform: 'scale(1.5)',  // size
                      }}
                      onChange={() => {
                        const newCheckedState = { ...isChecked, [family]: !isChecked[family] };
                        setIsChecked(newCheckedState);
                        handleCheckboxChange(family, newCheckedState[family]);
                      }}
                      checked={isChecked[family] || false}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <span style={styles.subtitiles}>select type you want to view and change the color</span>
        </div>
        
      )}
      
    </div>
  );
  
};

const styles = {
  container: {
    margin: 'auto',
    backgroundColor: "white",
    paddingTop: 0,
    paddingLeft: 7,
    paddingRIght: 7,
    paddingBottom: 5,
    textAlign: 'center',
  },
  subtitiles: {
    fontSize: '70%',
    color: "black",
  },
};  

export default CustomForm1;