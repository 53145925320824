import React, { useEffect,useState } from "react";
import '../style/Modal.css';
import ModalChoice from "./ModalChoice";

function ModalArticles(props) {

  const { data, input, dataUpdate} = props;
  const [fieldNames, setFieldNames] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [selectedPubMedId, setSelectedPubMedId] = useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  
  
  useEffect(() => {
    if (data && data.length > 0 && typeof data !== "string") {
      setFieldNames(Object.keys(data[0]));
      setIsLoading(false);
      setError(false);
    }
    if (typeof data === "string"){
      setError(true)
    }
  }, [data]);

  useEffect(() => {
    setKeyword(input)
  }, [input]);

  useEffect(() => {
    if (input !== keyword) {
      setIsLoading(true);
      setKeyword(input);
    }
  }, [input, keyword]);

  const updateData = (newData) => {
    if (newData === "Error"){
      console.log("ERROR");
    }
    dataUpdate(newData);
    // Esempio di aggiornamento dello stato con i nuovi dati
    // setDati(newData);
  };
  
  
  const handleCheckboxChange = (pubMedId) => {
    setSelectedPubMedId(pubMedId);
  };

  const handleCloseChoice = () => {
    window.location.reload();
  };


  return (
    <div>
      <ModalChoice pubMedId={selectedPubMedId} onDataUpdate={updateData}/>
      <div className="fade modal modal-xl" id="modalArticles">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Articles with keyword: {keyword}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              { error ? (<div className="text-center"><p className="text-danger">Error: Unable to send request, please double check parameters and try again</p><button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseChoice}>Close</button></div>) : (isLoading ? ( //show spinner if data is loading
                <div className="text-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : ( //show table
                <div className="table-responsive">
                <table className="table table-striped table-bordered center-text align-middle">
                  <thead>
                    <tr>
                      {fieldNames.map((fieldName, index) => (
                        index !== 5 && index !== 3 && (
                        <th key={fieldName} className={index === 1 ? 'center-text wide-column' : 'center-text narrow-column'}>{index === 0 ? 'PubMedId' : fieldName}</th>
                      )                      
                      ))}
                      <th scope="col">selected</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index} className="separated-row">
                        <th scope="row" className="center-text narrow-column" ><a className="no-underline" target="_blank" href={`https://pubmed.ncbi.nlm.nih.gov/${item[fieldNames[0]]}`}>{item[fieldNames[0]]}</a></th>
                        <td className="truncate-text wide-column">
                          {typeof item[fieldNames[1]] === 'string' ? (
                            <>
                              {item[fieldNames[1]].split(' ').slice(0, 50).join(' ')}
                              {item[fieldNames[1]].split(' ').length > 50 && (
                                <span className="more-text">
                                  {item[fieldNames[1]].split(' ').slice(50).join(' ')}
                                </span>
                              )}
                            </>
                          ) : ''}
                        </td>
                        <td className="center-text narrow-column">
                          {Array.isArray(item[fieldNames[2]]) ? (
                            item[fieldNames[2]].length === 1 ? (
                              `${item[fieldNames[2]][0].firstname} ${item[fieldNames[2]][0].initials} ${item[fieldNames[2]][0].lastname}`
                            ) : (
                              `${item[fieldNames[2]][0].firstname} ${item[fieldNames[2]][0].initials} ${item[fieldNames[2]][0].lastname} etc.`
                            )
                          ) : (
                            ''
                          )}
                        </td>
                        <td className="center-text narrow-column">{item[fieldNames[4]]}</td>
                        <td className="center-text narrow-column">{item[fieldNames[6]]}</td>
                        <td className="center-text narrow-column">
                          <input 
                            type="checkbox" 
                            className="large-checkbox" 
                            checked={selectedPubMedId === item[fieldNames[0]]}
                            onChange={() => handleCheckboxChange(item[fieldNames[0]])} 
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!error && (<div className="modal-foot text-center"><button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalChoice"  disabled={!selectedPubMedId}>Confirm</button></div>)}
              </div>
              ))}
              
            </div>

            {/*!error &&(<div className="modal-foot text-center">
              <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#modalChoice"  disabled={!selectedPubMedId}>Confirm</button>
            </div>)*/}
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}  

export default ModalArticles;