import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../style/RateEdge.css';

function RateEdge ({ goodRate, averageRate, badRate, edgeRate, ratedEdge }) {

    const handleSave = async () => {

        console.log(JSON.stringify({rating: ratedEdge}));
        
        const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({rating: ratedEdge})
        };

        try {
            const response = await fetch('https://bio-viber-api.anacleto.di.unimi.it/update_rating', reqOptions);
            if (!response.ok) {
                throw new Error(`Error: ${response.status}`)
            }
            const data = await response.json();
            console.log(`Response: ${data.message}`);
        }        
        catch (error) {
            console.log(error.message);
        }
    };

    let txt_clr = '';
    if (edgeRate === 'good') {
        txt_clr = 'success';
    }
    else if (edgeRate === 'average') {
        txt_clr = 'warning';
    }
    else {
        txt_clr = 'danger';
    }


    return (
        <>
            <div className="modal fade" id="rateModal" tabIndex="-1" aria-labelledby="rateModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="rateModalLabel">Rate the edge plausibility</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body modal-rate">
                            <div className="rate-face">
                                <button className="rate-btn good" onClick={goodRate}>&#128578;</button>
                                <button className="rate-btn average" onClick={averageRate}>&#128528;</button>
                                <button className="rate-btn bad" onClick={badRate}>&#128577;</button>
                            </div>
                            {edgeRate !== '' ? <p className={`text-${txt_clr}`}> Do you want to rate this edge as <strong>{edgeRate}</strong>?</p> : <></>}
                        </div>
                        
                        { edgeRate !== '' ?
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary">Save & Enhance schema</button>
                                <button type="button" className="btn btn-success" onClick={handleSave} >Save</button>
                            </div>
                            : <></>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default RateEdge;