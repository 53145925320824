import React from "react";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "../style/HomePage.css";
import { FaSearch } from "react-icons/fa";
import ModalArticles from "./ModalArticles";

function HomePage() {

    const navigate = useNavigate();
    const location = useLocation();
    const [inputValue, setInputValue] = useState("");
    const [abstractData, setAbstractData] = useState([]);
    const [newData, setNewData] = useState({});

    // User email
    const userEmail = location.state;

    const handleClick = async () => {
        const query = inputValue.split(" ").join(" AND ");
        try {
            const api_query = `https://bio-viber-api.anacleto.di.unimi.it/pubmed_gathering?query="${query}"&max=10`;
            const response = await fetch(api_query);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setAbstractData(data);
            return data;
        } catch (error) {
            const data = "Error";
            console.log(error.message);
            return data;
        }
    };

    const prova = (data) => {
        if (data === "Error") {
            console.log("ERROR");
        }

        console.log(data);
        setNewData(data);
    };

    const handleCreateGraph = () => {
        // console.log(newData);
        // Send triples data to DisplayGraph
        navigate("/displayGraph", {
            state: newData,
            state2: userEmail
        }); 
    };

    return (
        <>
            <ModalArticles
                data={abstractData}
                input={inputValue}
                dataUpdate={prova}
            />
            <div className="main-container">
                <div className="modal fade" id="formModal" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Relation form</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="source"
                                            placeholder="Source"
                                        />
                                        <label for="source">Source node</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="target"
                                            placeholder="Target"
                                        />
                                        <label for="target">Target node</label>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="rel"
                                            placeholder="Relation"
                                        />
                                        <label for="rel">Relation</label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row title-row ">
                        <div className="col content-container" style={{backgrounColor: 'none'}}>
                            <span className="title">Bio-Viber</span>
                            <p className="titleUnder">
                                <b>
                                    Virtual Intelligent Biomedical Relation
                                    Extraction Assistant
                                </b>
                            </p>
                        </div>
                    </div>

                    <div className="row align-items-center">

                        <div className="col">
                            <div className="header">
                                <h3>Graph Name</h3>
                            </div>

                            <div className="input-group ml-module">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="option4"
                                        id="flexRadioDefault1"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                    >
                                        RNA-KG
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col repo-col">
                            <div className="header">
                                <h3>Relation Repository</h3>
                                <p>Choose entry method</p>
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input
                                        className="form-check-input mt-0"
                                        name="option"
                                        type="radio"
                                        value=""
                                        aria-label="Radio button for following text input"
                                        checked
                                    />
                                </div>
                                <input
                                    id="inputGPT"
                                    type="text"
                                    className="form-control"
                                    aria-describedby="button-addon2"
                                    placeholder="✏️ Google PubMed and select an article"
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                    }}
                                />
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    id="button-addon2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#modalArticles"
                                    disabled={!inputValue.trim()}
                                    onClick={handleClick}
                                >
                                    <span>
                                        <FaSearch />
                                    </span>
                                </button>
                            </div>

                            <div className="input-group mb-3">
                                <div className="input-group-text">
                                    <input
                                        className="form-check-input mt-0"
                                        name="option"
                                        type="radio"
                                        value=""
                                        aria-label="Radio button for following text input"
                                    />
                                </div>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="inputGroupFile02"
                                />
                            </div>

                            <div className="input-group form">
                                <div className="input-group-text ">
                                    <input
                                        className="form-check-input mt-0"
                                        name="option"
                                        type="radio"
                                        value=""
                                        aria-label="Radio button"
                                    />
                                </div>
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#formModal"
                                >
                                    Fill form manually
                                </button>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        
                        <div className="col ml short">
                            <div className="header">
                                <h3>ML Module</h3>
                            </div>

                            <div className="input-group ml-module">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="option2"
                                        id="flexRadioDefault1"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                    >
                                        TransE + Random Forest
                                    </label>
                                </div>

                            </div>
                        </div>

                        <div className="col short">
                            <div className="header extract">
                                <h3>Extraction method</h3>
                            </div>

                            <div className="input-group ml-module">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="option5"
                                        id="flexRadioDefault1"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                    >
                                        SPIRES
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="col short">
                            <div className="header">
                                <h3>Visualize method</h3>
                            </div>

                            <div className="input-group ml-module">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="option3"
                                        id="flexRadioDefault1"
                                        checked
                                    />
                                    <label
                                        className="form-check-label"
                                        for="flexRadioDefault1"
                                    >
                                        Steiner
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* graph name, vis method here */}


                    </div>
                    <div className="row btn-row">
                        <div className="col col-22">
                            <button 
                                className="btn btn-primary" 
                                type="button" 
                                data-bs-toggle="modal" 
                                data-bs-target="#modalAfterChoice"
                                onClick={handleCreateGraph}
                                >
                                Run
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomePage;
