import { useEffect,useState } from "react";
import { useSigma } from "@react-sigma/core";
import { useLoadGraph } from "@react-sigma/core";
import { MultiGraph } from "graphology";
import * as d3 from 'd3';
import { DEFAULT_EDGE_CURVATURE, indexParallelEdgesIndex } from "@sigma/edge-curve";
import FA2Layout from "graphology-layout-forceatlas2/worker";
import forceAtlas2 from "graphology-layout-forceatlas2";
import "@react-sigma/core/lib/react-sigma.min.css";
import EventsGraph from "./EventsGraph";
import MenuNode from "./MenuNode";
import MenuEdge from "./MenuEdge";

import "../library/CustomShapesPlugin.js";


function LoadGraph({confidenceEdges, markedMyEdges, myDisplayGraph, mySetDisplayGraph, graphInfo, viewMenuAttribute, mySelectedNodeDisplay, mySelectedEdgeDisplay,  colorOfMyFamilys, changeColorOfMyFamilys, viewNodesFamily, confidenceLevel, labelOfMyEdge, familyWithConfidence, viewEdgeFamily, acceptEdgeFamily, pressButtonEdge, numerOfEdgeFamily, fa2Layout, nodeSize, edgeSize, edgeForDisplay, allEdgeToPass, dataForGraph}) {    //colorOfMyFamilys var for color modify menù, changeColorOfMyFamilys possibility to set color
  const loadGraph = useLoadGraph();
  const [currentNode, setCurrentNode] = useState(null);   //node selected
  const [selectedNode, setSelectedNode] = useState(null);     //node on which the cursor is positioned
  const [currentEdge, setCurrentEdge] = useState(null);   //edge selected
  const [selectedEdge, setSelectedEdge] = useState(null);     //edge on which the cursor is positioned
  const [contextMenuNodeVisible, setContextMenuNodeVisible] = useState(false);    //handle menu node (on right click)
  const [contextMenuEdgeVisible, setContextMenuEdgeVisible] = useState(false);    //handle menu edge (on right click)
  const [posx, setPosX] = useState(null);   //X position
  const [posy, setPosY] = useState(null);   //Y position
  const [graph, setGraph] = useState(new MultiGraph());      //my graph with nodes and edges
  const [familyColor, setFamilyColor] = useState({});   //object that contain {family:'color'} of all family in graph, this allows me to change real-time the node color based family
  const [markedEdges, setMarkedEdges] = useState(0);    //number of edge marked
  const [totOfConfidence, setTotOfConfidence] = useState(0);    //number of edge marked
  const [sizeOfNode, setSizeOfNode] = useState(10);  
  const [sizeOfEdge, setSizeOfEdge] = useState(3);
  const [dataGraph, setDataGraph] = useState({});

  const sigma = useSigma(); 
  let allNodesData = [];
  let nodesData = [];
  let edgesData = [];
  const familyColorMap = {};


  //sigma.setMaxListeners(200);
  const sensibleSettings = forceAtlas2.inferSettings(graph);
  sensibleSettings.linLogMode = false; // Disattiva la modalità logaritmica lineare
  sensibleSettings.adjustSizes = false; // Disabilita la regolazione dinamica delle dimensioni dei nodi
  sensibleSettings.edgeWeightInfluence = 0; // Imposta l'influenza del peso dell'arco sul movimento dei nodi a 0 per ignorare i pesi degli archi
  sensibleSettings.scalingRatio = 0.1; // Imposta il rapporto di scala a 1 per ridurre la velocità delle forze
  sensibleSettings.strongGravityMode = true; // Disattiva la modalità di gravità forte
  sensibleSettings.gravity = 1; // Imposta una bassa forza di gravità per mantenere i nodi vicini al centro
  sensibleSettings.theta = 0.8; // Imposta un alto valore di theta per una maggiore sensibilità alla distanza tra i nodi
  sensibleSettings.slowDown = 1 // Imposta la velocità di raffreddamento a 1 per evitare un rallentamento eccessivo
  sensibleSettings.worker = true; // Abilita l'uso di worker per il calcolo del layout in parallelo
  sensibleSettings.barnesHutOptimize = true; // Attiva l'ottimizzazione di Barnes-Hut per evitare problemi di sovrapposizione

  //const sensibleSettings = forceAtlas2.inferSettings(graph);    //infer the settings from graph (automatich)
  
  const layout  = new FA2Layout(graph, {
    iterations: 1,
    settings: sensibleSettings,
  });

  const oppositeEdgeLabels  = [
    ["has gene product", "gene product of"],
    ["has intracellular endoparasite", "intracellular endoparasite of"],
    ["has partial material basis in germline mutation in", "is causal germline mutation partially giving rise to"],
    ["expressed in", "expresses"],
    ["pathogen of", "has pathogen"],
    ["starts during", "during which starts"],
    ["is genetic basis for condition", "condition has genetic basis in"],
    ["is killed by", "kills"],
    ["immediately causally downstream of", "immediately causally upstream of"],
    ["is autoimmune trigger for", "has autoimmune trigger"],
    ["phenotype of", "has phenotype"],
    ["determines", "determined by"],
    ["synapsed by", "synapsed to"],
    ["ends during", "during which ends"],
    ["has start sequence", "is start sequence of"],
    ["visits flowers of", "has flowers visited by"],
    ["is vector for", "has vector"],
    ["parasite of", "parasitized by"],
    ["has model", "has role in modeling"],
    ["is basis for realizable", "realizable has basis in"],
    ["synapsed_by_via_type_ib_bouton", "synapsed_via_type_ib_bouton_to"],
    ["has intercellular endoparasite", "intercellular endoparasite of"],
    ["regulates", "regulated by"],
    ["has eggs laid in by", "lays eggs in"],
    ["is small molecule activator of", "has small molecule activator"],
    ["parasitoid of", "has parasitoid"],
    ["has flowers visited by", "visits flowers of"],
    ["has characteristic", "characteristic of"],
    ["has allergic trigger", "is allergic trigger for"],
    ["has phenotype", "phenotype of"],
    ["hyperparasite of", "hyperparasitized by"],
    ["ends", "ends with"],
    ["is causal susceptibility factor for", "has major susceptibility factor"],
    ["has branching part", "branching part of"],
    ["bounds sequence of", "is bound by sequence of"],
    ["intercellular endoparasite of", "has intercellular endoparasite"],
    ["provides nutrients for", "acquires nutrients from"],
    ["is start sequence of", "has start sequence"],
    ["reservoir host of", "has reservoir host"],
    ["is end sequence of", "has end sequence"],
    ["has participant", "participates in"],
    ["skeleton of", "has skeleton"],
    ["generically depends on", "is carrier of"],
    ["location of", "located in"],
    ["preyed upon by", "preys on"],
    ["sends synaptic output to cell", "receives synaptic input from neuron"],
    ["has active ingredient", "active ingredient in"],
    ["directly positively regulated by", "directly positively regulates"],
    ["has exemplar data", "exemplar data of"],
    ["has mesoparasite", "mesoparasite of"],
    ["synapsed_via_type_is_bouton_to", "synapsed_by_via_type_is_bouton"],
    ["has member", "member of"],
    ["is treated by substance", "is substance that treats"],
    ["developmentally induces", "developmentally induced by"],
    ["has material basis in gain of function germline mutation in", "is causal gain of function germline mutation of in"],
    ["directly regulates", "directly regulated by"],
    ["transcribed to", "transcribed from"],
    ["participates in", "has participant"],
    ["is causal germline mutation in", "has material basis in germline mutation in"],
    ["myristoylates", "myristoylated by"],
    ["enabled by", "enables"],
    ["pollinates", "pollinated by"],
    ["synapsed_via_type_ii_bouton_to", "synapsed_by_via_type_ii_bouton"],
    ["transcribed from", "transcribed to"],
    ["visited by", "visits"],
    ["myristoylated by", "myristoylates"],
    ["derived by descent from", "has derived by descendant"],
    ["has end sequence", "is end sequence of"],
    ["is small molecule regulator of", "has small molecule regulator"],
    ["parasitized by", "parasite of"],
    ["causally downstream of or within", "causally upstream of or within"],
    ["positively regulated by", "positively regulates"],
    ["ribosomal translation of", "ribosomally translates to"],
    ["ectoparasite of", "has ectoparasite"],
    ["has developmental contribution from", "developmentally contributes to"],
    ["has pathogen", "pathogen of"],
    ["during which starts", "starts during"],
    ["is subsequence of", "has subsequence"],
    ["produced by", "produces"],
    ["has material basis in somatic mutation in", "is causal somatic mutation in"],
    ["Causally influenced by", "Causally influences"],
    ["has synaptic terminal of", "has synaptic terminal in"],
    ["causally upstream of", "causally downstream of"],
    ["has vector", "is vector for"],
    ["preys on", "preyed upon by"],
    ["starts with", "starts"],
    ["has output", "output of"],
    ["2d boundary of", "has 2d boundary"],
    ["intracellular endoparasite of", "has intracellular endoparasite"],
    ["pollinated by", "pollinates"],
    ["endoparasite of", "has endoparasite"],
    ["receives input from", "sends output to"],
    ["has fasciculating neuron projection", "fasciculates with"],
    ["directly positively regulates", "directly positively regulated by"],
    ["epiphyte of", "has epiphyte"],
    ["has postsynaptic terminal in", "has presynaptic terminal in"],
    ["causally upstream of or within", "causally downstream of or within"],
    ["has material basis in germline mutation in", "is causal germline mutation in"],
    ["has autoimmune trigger", "is autoimmune trigger for"],
    ["gene product of", "has gene product"],
    ["condition has genetic basis in", "is genetic basis for condition"],
    ["has quality", "quality of"],
    ["directly regulated by", "directly regulates"],
    ["directly negatively regulated by", "directly negatively regulates"],
    ["developmentally induced by", "developmentally induces"],
    ["lays eggs in", "has eggs laid in by"],
    ["has parasitoid", "parasitoid of"],
    ["connecting branch of", "has connecting branch"],
    ["happens during", "encompasses"],
    ["branching part of", "has branching part"],
    ["function of", "has function"],
    ["ribosomally translates to", "ribosomal translation of"],
    ["immediately precedes", "immediately preceded by"],
    ["derives from", "derives into"],
    ["sends output to", "receives input from"],
    ["role of", "has role"],
    ["derives into", "derives from"],
    ["hyperparasitized by", "hyperparasite of"],
    ["lays eggs on", "has eggs laid on by"],
    ["kleptoparasite of", "kleptoparasitized by"],
    ["develops into", "develops from"],
    ["developmentally preceded by", "developmentally succeeded by"],
    ["is upstream of sequence of", "is downstream of sequence of"],
    ["is direct conjugate base of", "is direct conjugate acid of"],
    ["surrounds", "surrounded by"],
    ["mesoparasite of", "has mesoparasite"],
    ["active ingredient in", "has active ingredient"],
    ["ends with", "ends"],
    ["has small molecule activator", "is small molecule activator of"],
    ["has eggs laid on by", "lays eggs on"],
    ["is causal somatic mutation in", "has material basis in somatic mutation in"],
    ["has major susceptibility factor", "is causal susceptibility factor for"],
    ["formed as result of", "results in formation of anatomical entity"],
    ["has input", "input of"],
    ["produces", "produced by"],
    ["visits", "visited by"],
    ["surrounded by", "surrounds"],
    ["is causal gain of function germline mutation of in", "has material basis in gain of function germline mutation in"],
    ["causally downstream of", "causally upstream of"],
    ["is causal germline mutation partially giving rise to", "has partial material basis in germline mutation in"],
    ["is allergic trigger for", "has allergic trigger"],
    ["exemplar data of", "has exemplar data"],
    ["synapsed to", "synapsed by"],
    ["is bound by sequence of", "bounds sequence of"],
    ["synapsed_by_via_type_ii_bouton", "synapsed_via_type_ii_bouton_to"],
    ["innervates", "innervated_by"],
    ["has 2d boundary", "2d boundary of"],
    ["has disposition", "disposition of"],
    ["is carrier of", "generically depends on"],
    ["has reservoir host", "reservoir host of"],
    ["synapsed_by_via_type_iii_bouton", "synapsed_via_type_iii_bouton_to"],
    ["negatively regulated by", "negatively regulates"],
    ["has derived by descendant", "derived by descent from"],
    ["causal agent in process", "process has causal agent"],
    ["synapsed_by_via_type_is_bouton", "synapsed_via_type_is_bouton_to"],
    ["directly negatively regulates", "directly negatively regulated by"],
    ["expresses", "expressed in"],
    ["located in", "location of"],
    ["immediately preceded by", "immediately precedes"],
    ["develops from", "develops into"],
    ["results in formation of anatomical entity", "formed as result of"],
    ["starts", "starts with"],
    ["output of", "has output"],
    ["has skeleton", "skeleton of"],
    ["receives synaptic input from neuron", "sends synaptic output to cell"],
    ["disposition of", "has disposition"],
    ["has presynaptic terminal in", "has postsynaptic terminal in"],
    ["has small molecule inhibitor", "is small molecule inhibitor of"],
    ["directly develops into", "directly develops from"],
    ["is protonated form of", "is deprotonated form of"],
    ["has synaptic terminal in", "has synaptic terminal of"],
    ["during which ends", "ends during"],
    ["input of", "has input"],
    ["is deprotonated form of", "is protonated form of"],
    ["has epiphyte", "epiphyte of"],
    ["encompasses", "happens during"],
    ["is substance that treats", "is treated by substance"],
    ["has evidence", "is evidence for"],
    ["member of", "has member"],
    ["has subsequence", "is subsequence of"],
    ["realizable has basis in", "is basis for realizable"],
    ["regulated by", "regulates"],
    ["directly develops from", "directly develops into"],
    ["is direct conjugate acid of", "is direct conjugate base of"],
    ["is causal loss of function germline mutation of in", "has material basis in loss of function germline mutation in"],
    ["ubiquitously expressed in", "ubiquitously expresses"],
    ["is small molecule inhibitor of", "has small molecule inhibitor"],
    ["developmentally contributes to", "has developmental contribution from"],
    ["developmentally succeeded by", "developmentally preceded by"],
    ["quality of", "has quality"],
    ["is concretized as", "concretizes"],
    ["kleptoparasitized by", "kleptoparasite of"],
    ["negatively regulates", "negatively regulated by"],
    ["has role", "role of"],
    ["has small molecule regulator", "is small molecule regulator of"],
    ["acquires nutrients from", "provides nutrients for"],
    ["has connecting branch", "connecting branch of"],
    ["enables", "enabled by"],
    ["has endoparasite", "endoparasite of"],
    ["has role in modeling", "has model"],
    ["fasciculates with", "has fasciculating neuron projection"],
    ["ubiquitously expresses", "ubiquitously expressed in"],
    ["synapsed_via_type_ib_bouton_to", "synapsed_by_via_type_ib_bouton"],
    ["is evidence for", "has evidence"],
    ["innervated_by", "innervates"],
    ["characteristic of", "has characteristic"],
    ["has function", "function of"],
    ["concretizes", "is concretized as"],
    ["synapsed_via_type_iii_bouton_to", "synapsed_by_via_type_iii_bouton"],
    ["kills", "is killed by"],
    ["is downstream of sequence of", "is upstream of sequence of"],
    ["immediately causally upstream of", "immediately causally downstream of"],
    ["process has causal agent", "causal agent in process"],
    ["determined by", "determines"],
    ["positively regulates", "positively regulated by"],
    ["has material basis in loss of function germline mutation in", "is causal loss of function germline mutation of in"],
    ["has ectoparasite", "ectoparasite of"],
    ["part of", "has part"],
    ["has part", "part of"]
  ];


  useEffect(() => {     //when graph o sigma change.
    setDataGraph(dataForGraph);
    graph.setMaxListeners(500);

  }, [dataForGraph]);

  //FUNCTION TO DISPLAY THE GRAPH AFTER THE GRAPH IS CHANGED
  useEffect(() => {     //when graph o sigma change.
    sigma.setGraph(graph);    //insert new graph
    sigma.refresh();  //refresh of interface for to see the changes
  }, [sigma, graph]);

  useEffect(() => {     //when change graph in DisplayGraph component
    setGraph(myDisplayGraph)
  }, [myDisplayGraph]);

  useEffect(() => {
    //console.log(layout);
    if (fa2Layout === 'fa2') {
      layout.start();
      //console.log("start");
    }else if (fa2Layout === 'normal') {
      layout.stop();
      //layout.kill();
      //console.log("kill");
    }    
    //refresh
    setGraph(graph); 
  }, [fa2Layout]);

  useEffect(() => {
    const allEdgesToPass = [];

    graph.edges().forEach(edge => {
      const properties = graph.getEdgeAttributes(edge);
      const edgeObject = {
        start: graph.source(edge),
        end: graph.target(edge),
        identity: edge,
        label: properties.label,
        properties: properties
      };
      allEdgesToPass.push(edgeObject);
    });

    allEdgeToPass(allEdgesToPass)
  },[graph, confidenceEdges, totOfConfidence, graphInfo])

  //view the real-time information of the graph
  useEffect(() => {
    
    let count = 0;
    let inverse = 0;
    //how many edge have the confidence value?
    graph.edges().forEach(edge => {
      if (graph.getEdgeAttribute(edge, '_confidence') !== undefined) {
        const sourceNode = graph.source(edge);
        const targetNode = graph.target(edge);
        if (!graph.hasEdge(targetNode, sourceNode)) {
          count++;
        }else{
          inverse++;
        }
      }
    });
    graphInfo({'node(s)':graph.order, 'edge(s)': graph.size, 'type': graph.type})   //node number, link number, type of graph
    setTotOfConfidence(count+(inverse/2));
    confidenceEdges(totOfConfidence)
  }, [graph, confidenceEdges, totOfConfidence, graphInfo, dataForGraph, setDataGraph, dataGraph])


/**
 *   useEffect(() => {
    if (graph && graph.edges && graph.getEdgeAttribute) {
      let count = 0;
      let inverse = 0;
  
      // Iterate over all edges
      graph.edges().forEach(edge => {
        const sourceNode = graph.source(edge);
        const targetNode = graph.target(edge);
  
        // Check if the current edge has '_confidence' attribute
        if (graph.getEdgeAttribute(edge, '_confidence') !== undefined) {
          // Check all edges between source and target
          const allEdges = graph.edges(sourceNode, targetNode);
          let hasOppositeEdge = false;
  
          allEdges.forEach(e => {
            if (e !== edge && graph.getEdgeAttribute(e, '_confidence') !== undefined) {
              hasOppositeEdge = true;
            }
          });
  
          // Count based on existence of opposite edges
          if (!hasOppositeEdge) {
            count++;
          } else {
            inverse++;
          }
        }
      });
  
      // Update graph information and state
      graphInfo({ nodes: graph.order, edges: graph.size, type: graph.type });
      setTotOfConfidence(count + (inverse / 2));
      confidenceEdges(totOfConfidence);
    }
  }, [graph, confidenceEdges, totOfConfidence, graphInfo]);
 */


  useEffect(()=> {
    markedMyEdges(markedEdges);   
  }, [markedMyEdges, markedEdges])


  useEffect(()=> {
    setSizeOfNode(nodeSize);
    graph.forEachNode((node, attributes) => {
      attributes.size = nodeSize;
    });  
    sigma.setGraph(graph);    //insert new graph
    sigma.refresh();  //refresh of interface for to see the changes
  }, [nodeSize])

  useEffect(()=> {
    setSizeOfEdge(edgeSize);
    graph.forEachEdge((edge, attributes) => {
      attributes.size = edgeSize;
    });  
    sigma.setGraph(graph);    //insert new graph
    sigma.refresh();  //refresh of interface for to see the changes
  }, [edgeSize])

//----------------LOADING DATA-----------------------------------------------------------------------------------------------------------------
  indexParallelEdgesIndex(graph, { edgeIndexAttribute: "parallelIndex", edgeMaxIndexAttribute: "parallelMaxIndex" });

  //FUNCTION REQUIRED FOR GRAPH DATA LOADING
  useEffect(() => {
  const fetchDataAndProcess = async () => {
    try {
      const familyLabel = [];
      const edgeWithConfidence = [];
      const newGraph = new MultiGraph(); // Ensure new graph is created

      dataGraph["result_nodes"].forEach((myNode) => {
        const randomX = Math.random() * 500;
        const randomY = Math.random() * 600;

        let label;
        
        if (myNode.properties && myNode.properties.Label !== undefined) {
          label = myNode.properties.Label;
        } else if (myNode.properties) {
          label = myNode.properties.name.split('/').pop();
        } else {
          label = myNode.identity;
        }

        if (!newGraph.hasNode(myNode.identity.toString())) {
          newGraph.addNode(
            myNode.identity.toString(),
            { label, family: myNode.labels, ...myNode.properties, x: randomX, y: randomY, size: 10 }
          );
        }
      });

      dataGraph["result_nodes"].forEach((myNode) => {
        myNode.labels.forEach((family) => {
          if (!familyColorMap[family]) {
            const color = d3.schemeCategory10[Object.keys(familyColorMap).length % 10];
            familyColorMap[family] = color;
          }
          newGraph.updateNodeAttribute(myNode.identity.toString(), 'color', () => familyColorMap[family]);
        });
      });

      const sameLabelPairs = {};
      const edgeCounts = {};
      const oppositeEdges = new Set();
      const result_edges = JSON.parse(dataGraph["result_edges"]);

      result_edges.forEach((edge) => {
        const { start, end, properties } = edge;
        const label = properties.label;
        const labelA = properties.label.replace(/_/g, '').toLowerCase();
        const key = [start, end, labelA].sort().join('_');
        edgeCounts[key] = (edgeCounts[key] || 0) + 1;

        if (start !== end && !sameLabelPairs.hasOwnProperty(start)) {
          sameLabelPairs[start] = {};
        }
        if (start !== end && !sameLabelPairs.hasOwnProperty(end)) {
            sameLabelPairs[end] = {};
        }

        if (start !== end && label === sameLabelPairs[start][end]) {
            edge.sameLabel = true; // Segna gli archi con la stessa label
        } else {
            sameLabelPairs[start][end] = label;
            sameLabelPairs[end][start] = label;
        }

        // Controlla se esiste un arco opposto
        if (start !== end && sameLabelPairs[end][start] === label) {
            oppositeEdges.add([start, end, label].sort().join('_'));
        }
      });

      const addedEdges = new Set();
      const allEdgesToPass = [];

      if (graph && graph.edges && graph.getEdgeAttributes) {
        result_edges.forEach((edge) => {
          if (edge.properties._confidence !== undefined){
            allEdgesToPass.push(edge)
          }
          
          const sourceNode = edge.start.toString();
          const targetNode = edge.end.toString();

          const { start, end, properties } = edge;
          const label = properties.label;
          const labelA = label.replace(/_/g, '').toLowerCase();
          const key = [start, end, labelA].sort().join('_');
          const count = edgeCounts[key];
          let edgeAttributes;
          

          if (addedEdges.has(key)) {
            // Skip adding this edge as it is a duplicate
            return;
          }
          /*if (checkBidirectional() === "True"){
              //LINE
          }else{
            
          }*/

          edgeAttributes = {
            ...edge.properties,
            label: edge.properties.label.replace(/_/g, ' '),
            type: "curved",
            Tipology: edge.label,
            size: 3,
            declined: false,
            accept: false,
          };

          /*if (oppositeEdges.has(key)) {
            edgeAttributes = {
              ...edge.properties,
              type: "line",
              Tipology: edge.properties.label.replace(/_/g, ' '),
              size: 1,
              decline: false,
              accept: false,
            };
          } else if ((start === end && count === 1) || count === 1) {
            edgeAttributes = {
              ...edge.properties,
              type: "straight",
              Tipology: edge.properties.label.replace(/_/g, ' '),
              size: 1,
              decline: false,
              accept: false,
            };
          } else if (start !== end && edge.sameLabel) {
            edgeAttributes = {
              ...edge.properties,
              type: "line",
              Tipology: edge.label,
              size: 1,
              decline: false,
              accept: false,
            };
          } else if (count > 1 && start === end) {
            edgeAttributes = {
              ...edge.properties,
              type: "straight",
              Tipology: edge.label,
              size: 1,
              decline: false,
              accept: false,
            };
          } else if (count > 1 && start !== end) {
            edgeAttributes = {
              ...edge.properties,
              type: "curved",
              Tipology: edge.label,
              size: 1,
              decline: false,
              accept: false,
            };
          }*/

          if (newGraph.hasNode(sourceNode) && newGraph.hasNode(targetNode) && !newGraph.hasEdge(edge.identity.toString())) {
            newGraph.addEdgeWithKey(
              edge.identity.toString(),
              sourceNode,
              targetNode,
              edgeAttributes
            );
            addedEdges.add(key);
          }

          if (!familyLabel.includes(edge.properties.label)) {
            familyLabel.push(edge.properties.label);
          }

          if (!edgeWithConfidence.includes(edge.properties.label)) {
            if (edge.properties._confidence) {
              edgeWithConfidence.push(edge.properties.label);
            }
          }
        });
        edgeForDisplay(allEdgesToPass)
      }

      labelOfMyEdge(familyLabel);
      familyWithConfidence(edgeWithConfidence);

      /*indexParallelEdgesIndex(newGraph, {
        edgeIndexAttribute: "parallelIndex",
        edgeMinIndexAttribute: "parallelMinIndex",
        edgeMaxIndexAttribute: "parallelMaxIndex",
      });*/

      result_edges.forEach((edge) => {
        if (newGraph.hasEdge(edge.identity.toString())) {
          if (newGraph.hasEdgeAttribute(edge.identity, '_confidence')) {
            newGraph.updateEdgeAttribute(edge.identity, 'color', () => getConfidenceColor(edge.properties._confidence));
          }
        }
      });

      setFamilyColor({ family: familyColorMap });
      setGraph(newGraph);
      loadGraph(newGraph);

    } catch (error) {
      //console.log("Problem:" + error);
    }
  };

  fetchDataAndProcess();
  indexParallelEdgesIndex(graph);
  // eslint-disable-next-line
}, [loadGraph, dataForGraph, dataGraph, setDataGraph]);


  useEffect(() => {
    indexParallelEdgesIndex(graph, {
      edgeIndexAttribute: "parallelIndex",
      edgeMinIndexAttribute: "parallelMinIndex",
      edgeMaxIndexAttribute: "parallelMaxIndex",
    });

    graph.forEachEdge(
      (edge, { parallelIndex, parallelMinIndex, parallelMaxIndex }) => {
        if (typeof parallelMinIndex === "number") {
          graph.mergeEdgeAttributes(edge, {
            type: parallelIndex ? "curved" : "straight",
            curvature: getCurvature(parallelIndex, parallelMaxIndex),
          });
        } else if (typeof parallelIndex === "number") {
          graph.mergeEdgeAttributes(edge, {
            type: "curved",
            curvature: getCurvature(parallelIndex, parallelMaxIndex),
          });
        } else {
          graph.setEdgeAttribute(edge, "type", "straight");
        }
      },
    );
  })
  

  function getCurvature(index, maxIndex) {
    if (maxIndex <= 0) console.log(maxIndex) //throw new Error("Invalid maxIndex", maxIndex);
    if (index < 0) return -getCurvature(-index, maxIndex);
    const amplitude = 3.5;
    const maxCurvature = amplitude * (1 - Math.exp(-maxIndex / amplitude)) * DEFAULT_EDGE_CURVATURE;
    return (maxCurvature * index) / maxIndex;
  }
  
  //assigns the color attribute at node based on the family provided
  /*const getFamilyColor = (() => {
    const uniqueFamilies = Array.from(new Set(nodesData.flatMap(nodo => nodo.family)));    //uniquely extracts the name of all the families
    const familyColorMap = new Map(uniqueFamilies.map((family, index) => [family, d3.schemeCategory10[index % d3.schemeCategory10.length]]));
    return (family) => familyColorMap.get(family);
  })();*/
  //assign the color attribute to the arc based on the confidence value provided
  const getConfidenceColor = (confidence) => {
    //define a color scale by d3
    const colorScale = d3.scaleLinear()
      .domain([0, 1])  //range: min size e max size
      .range(['red', 'blue']);    //bottom and top color of scale
    return colorScale(confidence);
  };


  //utilizziamo useEffect per iterare sugli archi del grafo quando il componente viene montato o quando cambiano le dipendenze specificate nell'array di dipendenze
  /*useEffect(() => {
    graph.forEachEdge((edge, {
      parallelIndex,
      parallelMinIndex,
      parallelMaxIndex,
    }) => {
      if (typeof parallelMinIndex === "number") {
        graph.mergeEdgeAttributes(edge, {
          type: parallelIndex ? "curved" : "straight",
          curvature: parallelIndex ? getCurvature(parallelIndex, parallelMaxIndex) : 0,
        });
      } else if (typeof parallelIndex === "number") {
        if (edge.type === 'straight') {
        } else if (edge.type === 'curved') {
          graph.mergeEdgeAttributes(edge, {
            type: "curved",
            curvature: getCurvature(parallelIndex, parallelMaxIndex),
          });
        }
      } else {
        graph.updateEdgeAttribute(edge, "type", () => "straight");
        graph.updateEdgeAttribute(edge, "curvature", () => 0); // Assicurati che l'arco retto abbia curvature 0
      }
  
      // Assicurati che la label dell'arco sia dritta quando l'arco è retto
      if (edge.type === 'straight') {
        graph.updateEdgeAttribute(edge, "label", () => ({ ...edge.label, align: 'horizontal' }));
      }
    });
    // eslint-disable-next-line
  }, [graph]);*/

//----------------OPTIONS GRAPH-----------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    changeColorOfMyFamilys(familyColor);    //every time it adds new families to which it assigns new colors it passes them to DisplayGraph (=original colors). At the same time it allows me to assign the new colors to familyColor
  }, [familyColor, changeColorOfMyFamilys]);
  
  //change the new family color every time I pass the new family-color object
  useEffect(() => {
    if (familyColor.family) {
      //check and update the color only if it is different
      Object.entries(familyColor.family).forEach(([family, color]) => {
        //take the family of each knot and check if they are equal
        const allNodes = graph.nodes();
        allNodes.forEach((nodeId) => {
          const familyArray = graph.getNodeAttribute(nodeId, "family");
          const familyContent = familyArray.length === 1 ? familyArray[0] : null;
          if (family === familyContent){
            graph.updateNodeAttribute(nodeId,'color', () => color);
          }
        });
      })
    }
  // eslint-disable-next-line
  }, [colorOfMyFamilys, familyColor, nodesData]);
  //hide unselected families and display selected families
  useEffect(() => {
    Object.entries(viewNodesFamily).forEach(([key, value]) => {   //key = name of family
      Object.entries(graph.nodes()).forEach(([id, node]) => {
        //this comment temporarily disable the warning message:
        // eslint-disable-next-line
        if (graph.getNodeAttributes(node).family == key){
          if (value === true){
            graph.updateNodeAttribute(node, 'hidden', () => false);   //set hidden attribute false because this node belong to family
          }else{
            graph.updateNodeAttribute(node, 'hidden', () => true);
          } 
        }
      })
    });
  }, [viewNodesFamily, graph]);
  //view only edges that have a confidence level greater than the selected
  useEffect(() => {
    const edges = graph.edges();
    edges.map((key, value) => (
      (graph.hasEdgeAttribute(key,'_confidence')) ? (graph.getEdgeAttribute(key,'_confidence') > confidenceLevel) ? graph.updateEdgeAttribute(key, 'hidden', () => false) : graph.updateEdgeAttribute(key, 'hidden', () => true) : null
    ))
  }, [confidenceLevel, graph]);



  //CONFIDENCE OF EDGES: view in 'confident of edges' only family that have edges with confident
  useEffect(() => {
    const edgeWithConfidence = [];
    const allEdges = graph.edges();
    // eslint-disable-next-line
    allEdges.map((edge,index) => {
      if (!edgeWithConfidence.includes(graph.getEdgeAttributes(edge).label)) {
        if (graph.getEdgeAttributes(edge)._confidence){
          edgeWithConfidence.push(graph.getEdgeAttributes(edge).label);
        }
      }
    })
    familyWithConfidence(edgeWithConfidence)
    // eslint-disable-next-line
  }, [markedEdges]);

  //CONFIDENCE OF EDGES
  useEffect(() => {
    let cont = markedEdges;

    if (pressButtonEdge === true && markedEdges !== totOfConfidence){
      const countedEdges = new Set();

      Object.entries(acceptEdgeFamily).forEach(([key, value]) => {
        Object.entries(graph.edges()).forEach(([id, edge]) => {
          if (graph.getEdgeAttributes(edge)._confidence !== undefined && graph.getEdgeAttributes(edge).label === key){
            //edge=value with confidence and that family
            const sourceNode = graph.source(edge);
            const targetNode = graph.target(edge);
            
            const edges = graph.edges(sourceNode, targetNode);
            
            edges.forEach(newEdge => {
              if (!countedEdges.has(newEdge)) {
                countedEdges.add(newEdge);
                
                if (value.selected === true && graph.getEdgeAttributes(edge)._confidence >= confidenceLevel){
                  if (graph.getEdgeAttribute(edge, 'type') === 'line' || graph.getEdgeAttribute(edge, 'type') === 'curved') {
                    const inverseEdges = graph.edges(targetNode, sourceNode);
                    
                    inverseEdges.forEach(inverseEdge => {
                      if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') === graph.getEdgeAttribute(edge, '_confidence')){
                        graph.updateEdgeAttribute(edge, 'hidden', () => false);
                        graph.updateEdgeAttribute(edge, 'accept', () => true);
                        graph.removeEdgeAttribute(edge, '_confidence');
                        graph.updateEdgeAttribute(inverseEdge, 'hidden', () => false);
                        graph.updateEdgeAttribute(inverseEdge, 'accept', () => true);
                        graph.removeEdgeAttribute(inverseEdge, '_confidence');
                      }else if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') !== graph.getEdgeAttribute(edge, '_confidence')){
                        //console.log(graph.getEdgeAttribute(edge, "label"), sourceNode, targetNode)
                        graph.updateEdgeAttribute(edge, 'hidden', () => false);
                        graph.updateEdgeAttribute(edge, 'accept', () => true);
                        graph.removeEdgeAttribute(edge, '_confidence');
                        graph.updateEdgeAttribute(inverseEdge, 'hidden', () => false);
                        graph.updateEdgeAttribute(inverseEdge, 'accept', () => true);
                        graph.removeEdgeAttribute(inverseEdge, '_confidence');
                      }
                    })
                  } else if (graph.getEdgeAttribute(edge, 'type') === 'straight'){
                    graph.updateEdgeAttribute(edge, 'hidden', () => false);
                    graph.updateEdgeAttribute(edge, 'accept', () => true);
                    graph.removeEdgeAttribute(edge, '_confidence');
                  }
                  cont++;
                }                
              }

              
            })
          }
        });
      });
      setMarkedEdges(cont);
    }else if (pressButtonEdge === false && markedEdges !== totOfConfidence){
      const countedEdges = new Set();

      Object.entries(acceptEdgeFamily).forEach(([key, value]) => {
        Object.entries(graph.edges()).forEach(([id, edge]) => {
          if (graph.getEdgeAttributes(edge)._confidence !== undefined && graph.getEdgeAttributes(edge).label === key){
            //edge=value with confidence and that family
            const sourceNode = graph.source(edge);
            const targetNode = graph.target(edge);
            
            const edges = graph.edges(sourceNode, targetNode);
            edges.forEach(newEdge => {
              if (!countedEdges.has(newEdge)) {
                countedEdges.add(newEdge);
                
                if (value.selected === true && graph.getEdgeAttributes(edge)._confidence < confidenceLevel){
                  if (graph.getEdgeAttribute(edge, 'type') === 'line' || graph.getEdgeAttribute(edge, 'type') === 'curved') {
                    const inverseEdges = graph.edges(targetNode, sourceNode);
                    
                    inverseEdges.forEach(inverseEdge => {
                      if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') === graph.getEdgeAttribute(edge, '_confidence')){
                          graph.updateEdgeAttribute(edge, 'hidden', () => true);
                          graph.updateEdgeAttribute(edge, 'declined', () => true);
                          graph.removeEdgeAttribute(edge, '_confidence');
                          graph.updateEdgeAttribute(inverseEdge, 'hidden', () => true);
                          graph.updateEdgeAttribute(edge, 'declined', () => true);
                          graph.updateEdgeAttribute(inverseEdge, 'declined', () => true);
                          graph.removeEdgeAttribute(inverseEdge, '_confidence');
                      }else if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') !== graph.getEdgeAttribute(edge, '_confidence')){
                        //console.log(graph.getEdgeAttribute(edge, 'label'));
                        graph.updateEdgeAttribute(edge, 'hidden', () => true);
                        graph.updateEdgeAttribute(edge, 'decline', () => true);
                        graph.updateEdgeAttribute(edge, 'declined', () => true);
                        graph.removeEdgeAttribute(edge, '_confidence');
                        graph.updateEdgeAttribute(inverseEdge, 'hidden', () => true);
                        graph.updateEdgeAttribute(inverseEdge, 'decline', () => true);
                        graph.updateEdgeAttribute(inverseEdge, 'declined', () => true);
                        graph.removeEdgeAttribute(inverseEdge, '_confidence');
                      }
                    })
                  } else if (graph.getEdgeAttribute(edge, 'type') === 'straight'){
                      graph.updateEdgeAttribute(edge, 'hidden', () => true);
                      graph.updateEdgeAttribute(edge, 'decline', () => true);
                      graph.updateEdgeAttribute(edge, 'declined', () => true);
                      graph.removeEdgeAttribute(edge, '_confidence');
                  }
                  cont++;
                }                
              }

              
            })
          }
        });
      });
      setMarkedEdges(cont);
    }
  // eslint-disable-next-line
  }, [acceptEdgeFamily, pressButtonEdge, confidenceLevel, graph])


  useEffect(() => {
    const edgeWithConfidence = {};
    const visitedEdges = new Set();
    const visitedFamilies = new Set();
    const allEdges = graph.edges();
  
    allEdges.forEach((edge) => {
      const fromNode = graph.source(edge);
      const toNode = graph.target(edge);
      const family = graph.getEdgeAttributes(edge).label;
      const edgeType = graph.getEdgeAttribute(edge, 'type');
      //console.log("tipologia:",edgeType);
  
      const isBidirectionalEdge = edgeType !== 'curved' && visitedEdges.has(toNode + "-" + fromNode);
  
      if (!edgeWithConfidence[family]) {
        edgeWithConfidence[family] = 0;
      }
  
      // Check bi-directionality
      if (!isBidirectionalEdge && graph.getEdgeAttribute(edge, '_confidence') && edgeType !== 'curved') {
        edgeWithConfidence[family]++;
      }
  
      // Consider curved edges
      if (edgeType === 'curved' && !visitedFamilies.has(family)) {
        edgeWithConfidence[family]++;
        visitedFamilies.add(family);
      }
  
      visitedEdges.add(fromNode + "-" + toNode);
    });
  
    numerOfEdgeFamily(edgeWithConfidence);
  
    // eslint-disable-next-line
  }, [graph, markedEdges, confidenceLevel]);
  
  
  

//----------------MENU RIGHT CLICK--------------------------------------------------------------------------------------------------------------------
//-handle show node------------------------------------------------------------------------------------------------------------------------------
const handleShowName = () => {
  const name = graph.getNodeAttribute(selectedNode, 'name');
  const cleanedUrl = name.replace(/[<>]/g, ''); //remove '<' and '>'
  const decodedUrl = decodeURIComponent(cleanedUrl); //decode URL
  window.open(decodedUrl);
}

//-handle hide-----------------------------------------------------------------------------------------------------------------------------------
const handleHide = () => {
  const updatedGraph = graph.copy();
  if (selectedNode) {
    if (updatedGraph.hasNode(selectedNode)) {
      updatedGraph.dropNode(selectedNode);
      setSelectedNode(null);

      let count = 0;
      let inverse = 0;
      //how many edge have the confidence value?
      graph.edges().forEach(edge => {
        if (graph.getEdgeAttribute(edge, '_confidence') !== undefined) {
          const sourceNode = graph.source(edge);
          const targetNode = graph.target(edge);
          if (!graph.hasEdge(targetNode, sourceNode)) {
            count++;
          }else{
            inverse++;
          }
        }
      });
      
      //setTotOfConfidence(count+(inverse/2));
      confidenceEdges(count+(inverse/2))
    }else{
      console.log("NON esiste il nodo");
    }
  }else if (selectedEdge) {
    /*if (updatedGraph.getEdgeAttribute(selectedEdge,'type') === 'line' ){    //|| updatedGraph.getEdgeAttribute(selectedEdge,'type') === 'curve'
      const sourceNewNode = updatedGraph.source(selectedEdge);
      const  targetNewNode = updatedGraph.target(selectedEdge);
      const newEdge = updatedGraph.edges(targetNewNode, sourceNewNode)
      
      newEdge.forEach(newE => {
        if (updatedGraph.getEdgeAttributes(newE).label === updatedGraph.getEdgeAttributes(selectedEdge).label && selectedEdge!== newE){
          updatedGraph.dropEdge(newE);
        }
      })
    }*/
      if (updatedGraph.hasEdge(selectedEdge)) {
        const sourceNode = updatedGraph.source(selectedEdge);
        const targetNode = updatedGraph.target(selectedEdge);
  
        // Elimina gli archi opposti
        const inverseEdges = updatedGraph.edges(targetNode, sourceNode); // Considera gli archi da target a source
        inverseEdges.forEach(inverseEdge => {
          console.log(selectedEdge, inverseEdge);
          if (isOppositeEdge(selectedEdge, inverseEdge)) {
            updatedGraph.dropEdge(inverseEdge);
          }
        });
  
        // Elimina l'arco selezionato
        updatedGraph.dropEdge(selectedEdge);
        setSelectedEdge(null);
      }
  }
  setGraph(updatedGraph);
  graphInfo({'nodes':graph.order, 'edges': graph.size, 'type': graph.type})   //node number, link number, type of graph
}



//-handle neighbors-----------------------------------------------------------------------------------------------------------------------------------
const handleNeighbors = () => {
  const updatedGraph = graph.copy();
  if (selectedNode) {
    const neighbors = graph.neighbors(selectedNode);
    updatedGraph.forEachNode((node, attributes) => {
      //set visible only the selected node and its neighbors
      attributes.hidden = selectedNode !== node && !neighbors.includes(node);
    });
  }else if (selectedEdge) {
    updatedGraph.forEachNode((node, attributes) => {
      //set visible only the selected edge and its extreme
      const isNodeInSelectedEdge = node === updatedGraph.source(selectedEdge) || node === updatedGraph.target(selectedEdge);
      attributes.hidden = !isNodeInSelectedEdge;
    });
  }
  setGraph(updatedGraph);
}

//-handle graph-----------------------------------------------------------------------------------------------------------------------------------
  const handleShowAllNodes = () => {
    if (graph) {
      const updatedGraph = graph.copy();
      updatedGraph.forEachNode((node, attributes) => {
        //sets the hidden attribute to false for all nodes
        attributes.hidden = false;
      });
      setGraph(updatedGraph);
    }
  };

//-view attribute-----------------------------------------------------------------------------------------------------------------------------------
  const handleNodeAttributes = () => {
    if (selectedNode) {
      mySelectedNodeDisplay(selectedNode);
      mySetDisplayGraph(graph);
      viewMenuAttribute(true);
    }
  };

  const handleEdgeAttributes = () => {
    if (selectedEdge) {
      mySelectedEdgeDisplay(selectedEdge);
      mySetDisplayGraph(graph);
      viewMenuAttribute(true);
    }
  };

  

//-accept/decline confidence edges-----------------------------------------------------------------------------------------------------------------------------------
  /*const acceptEdge = () => {
    const countedEdges = new Set();
    
    const countEdge = (edge) => {
      const sourceNode = graph.source(edge);
      const targetNode = graph.target(edge);
      
      const edges = graph.edges(sourceNode, targetNode);
  
      edges.forEach(edge => {
        if (!countedEdges.has(edge)) {
          countedEdges.add(edge);
  
          if (graph.getEdgeAttribute(edge, 'type') === 'line' || graph.getEdgeAttribute(edge, 'type') === 'curved') {
            const inverseEdges = graph.edges(targetNode, sourceNode);
            
            inverseEdges.forEach(inverseEdge => {
              if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') === graph.getEdgeAttribute(edge, '_confidence')){
                graph.updateEdgeAttribute(edge, 'hidden', () => false);
                graph.updateEdgeAttribute(edge, 'accept', () => true);
                graph.removeEdgeAttribute(edge, '_confidence');
                graph.updateEdgeAttribute(inverseEdge, 'hidden', () => false);
                graph.updateEdgeAttribute(inverseEdge, 'accept', () => true);
                graph.removeEdgeAttribute(inverseEdge, '_confidence');
              }
            })
            
            setMarkedEdges(markedEdges + 1);
          } else if (graph.getEdgeAttribute(edge, 'type') === 'straight'){
            graph.updateEdgeAttribute(edge, 'hidden', () => false);
            graph.updateEdgeAttribute(edge, 'accept', () => true);
            graph.removeEdgeAttribute(edge, '_confidence');
            setMarkedEdges(markedEdges + 1);
          }
        }
      });
    };
  
    countEdge(selectedEdge);
  };*/

  const checkBidirectional = (currentEdge) => {
    const bidirectionalEdges = ["Interacts with", "Molecularly interacts with", "In similarity relationship with" ,"Overlaps sequence of"];
    for (let index = 0; index < bidirectionalEdges.length; index++) {
      const labelBidirectionalEdges = bidirectionalEdges[index];
      if (labelBidirectionalEdges == graph.getEdgeAttribute(currentEdge, 'label')) {
        return "True";
      }
    }
    return "False";
};

  


  const isBidirectional = (edge1, edge2) => {
    const sourceNode1 = graph.source(edge1);
    const targetNode1 = graph.target(edge1);
    const edgeLabel1 = graph.getEdgeAttribute(edge1, 'label');

    const sourceNode2 = graph.source(edge2);
    const targetNode2 = graph.target(edge2);
    const edgeLabel2 = graph.getEdgeAttribute(edge2, 'label');

    return edgeLabel1 === edgeLabel2 &&
           sourceNode1 === targetNode2 &&
           targetNode1 === sourceNode2;
  };

  useEffect(() => {
    const edges = graph.edges();
    const bidirectionalEdges = [];

    // Itera su ogni coppia di archi nel grafo
    for (let i = 0; i < edges.length; i++) {
        for (let j = i + 1; j < edges.length; j++) {
            const edge1 = edges[i];
            const edge2 = edges[j];
            if (isBidirectional(edge1, edge2)) {
                bidirectionalEdges.push([edge1, edge2]);
            }
        }
    }

    //Stampa le coppie di archi bidirezionali
    bidirectionalEdges.forEach(pair => {
        // console.log(`Edge ${pair[0]} and edge ${pair[1]} are bidirectional`);
    });

  }, [graph])

  const isOppositeEdge = (edge1, edge2) => {
    const sourceNode1 = graph.source(edge1);
    const targetNode1 = graph.target(edge1);
    const edgeLabel1 = graph.getEdgeAttribute(edge1, 'label');
    
    const sourceNode2 = graph.source(edge2);
    const targetNode2 = graph.target(edge2);
    const edgeLabel2 = graph.getEdgeAttribute(edge2, 'label');
    
    // Find if the labels are opposite pairs from the predefined list
    const inversePair = oppositeEdgeLabels.find(pair => 
      (pair[0] === edgeLabel1 && pair[1] === edgeLabel2) ||
      (pair[1] === edgeLabel1 && pair[0] === edgeLabel2)
    );

    if(inversePair){
          console.log(edgeLabel1, " ," , edgeLabel2);
    }

  
    // Check if edges are opposite in both direction (start1 = target2, target1 = start2)
    // and the labels form an inverse pair
    return inversePair && (sourceNode1 === targetNode2 && targetNode1 === sourceNode2);
  };

  useEffect(() => {
    const edges = graph.edges();
  
    edges.forEach(edge => {
      const edgeLabel = graph.getEdgeAttribute(edge, 'label');
  
      // Check if the label matches 'Causally influences'
      if (edgeLabel === 'Causally influences') {
        // console.log("Found 'Causally influences' edge:", edge);
  
        // Get the current source and target nodes of the edge
        const sourceNode = graph.source(edge);
        const targetNode = graph.target(edge);
  
        // Get the edge's attributes before dropping it
        const edgeAttributes = graph.getEdgeAttributes(edge);
  
        // Remove the current edge
        graph.dropEdge(edge);
  
        // Add the new edge with reversed direction and same attributes
        graph.addEdge(targetNode, sourceNode, edgeAttributes);
  
        console.log("Edge direction reversed:", targetNode, "->", sourceNode);
      }
    });
  }, [graph]);
  

  useEffect(() => {
    // Assuming your graph is updated, you can run this check every time it changes.
    const processEdges = () => {
      const edges = graph.edges();
      edges.forEach(edge1 => {
        edges.forEach(edge2 => {
          if (edge1 !== edge2 && isOppositeEdge(edge1, edge2, graph)) {
            // Do something when the edges are opposite
            // console.log("Found opposite edges:", edge1, edge2);
          }
        });
      });
    };
  
    if (graph) {
      processEdges(); // Run the check when graph is updated
    }
  
  }, [graph]); // Add `graph` as a dependency to trigger on graph updates
  
  
  

  const acceptEdge = () => {
    const countedEdges = new Set();

    const countEdge = (edge) => {
      const sourceNode = graph.source(edge);
      const targetNode = graph.target(edge);
      
      //const edges = graph.edges(sourceNode, targetNode);
  
      graph.updateEdgeAttribute(selectedEdge, 'hidden', () => false);
      graph.updateEdgeAttribute(selectedEdge, 'accept', () => true); // Assicurati che qui passi una funzione
      graph.removeEdgeAttribute(selectedEdge, '_confidence');
      setMarkedEdges(markedEdges + 1); 

        if (!countedEdges.has(selectedEdge)) {
          // Accetta l'inverso se esiste e se è un arco opposto
          const inverseEdges = graph.edges(targetNode, sourceNode); // Considera gli archi da target a source
          inverseEdges.forEach(inverseEdge => {
            if (!countedEdges.has(inverseEdge) && isOppositeEdge(selectedEdge, inverseEdge)) {
              countedEdges.add(inverseEdge);
              graph.updateEdgeAttribute(inverseEdge, 'hidden', () => false);
              graph.updateEdgeAttribute(inverseEdge, 'accept', () => true); // Assicurati che qui passi una funzione
              graph.removeEdgeAttribute(inverseEdge, '_confidence');
              setMarkedEdges(markedEdges + 1); 
            }
          });
          countedEdges.add(selectedEdge); // Aggiungi anche l'arco principale all'insieme dopo aver gestito l'inverso
        }
    };
  
    countEdge(selectedEdge);
  };
  

  /*const declineEdge = () => {
    const countedEdges = new Set();
  
    const countEdge = (edge) => {
      const sourceNode = graph.source(edge);
      const targetNode = graph.target(edge);
      const edges = graph.edges(sourceNode, targetNode);
      
      edges.forEach(edge => {
        if (!countedEdges.has(edge)) {
          countedEdges.add(edge);
  
          if (graph.getEdgeAttribute(edge, 'type') === 'line' || graph.getEdgeAttribute(edge, 'type') === 'curved') {
            const inverseEdges = graph.edges(targetNode, sourceNode);
            
            inverseEdges.forEach(inverseEdge => {
              if (edge !== inverseEdge && graph.getEdgeAttribute(inverseEdge, '_confidence') === graph.getEdgeAttribute(edge, '_confidence')){
                graph.updateEdgeAttribute(edge, 'hidden', () => true);
                graph.updateEdgeAttribute(edge, 'decline', () => true);
                graph.removeEdgeAttribute(edge, '_confidence');
                graph.updateEdgeAttribute(inverseEdge, 'hidden', () => true);
                graph.updateEdgeAttribute(inverseEdge, 'decline', () => true);
                graph.removeEdgeAttribute(inverseEdge, '_confidence');
              }
            })
            setMarkedEdges(markedEdges + 1);
          } else if (graph.getEdgeAttribute(edge, 'type') === 'straight'){
            graph.updateEdgeAttribute(edge, 'hidden', () => true);
            graph.updateEdgeAttribute(edge, 'decline', () => true);
            graph.removeEdgeAttribute(edge, '_confidence');
            setMarkedEdges(markedEdges + 1);
          }
        }
      });
    };
  
    countEdge(selectedEdge);
  };*/

  const declineEdge = () => {
    const countedEdges = new Set();   
    
    const countEdge = (edge) => {
      const sourceNode = graph.source(edge);
      const targetNode = graph.target(edge);
      
      //const edges = graph.edges(sourceNode, targetNode);
  
      graph.updateEdgeAttribute(selectedEdge, 'hidden', () => true);
      graph.updateEdgeAttribute(selectedEdge, 'declined', () => true);
      graph.removeEdgeAttribute(selectedEdge, '_confidence');
      setMarkedEdges(markedEdges + 1); 

        if (!countedEdges.has(selectedEdge)) {
          // Accetta l'inverso se esiste e se è un arco opposto
          const inverseEdges = graph.edges(targetNode, sourceNode); // Considera gli archi da target a source
          inverseEdges.forEach(inverseEdge => {
            if (!countedEdges.has(inverseEdge) && isOppositeEdge(selectedEdge, inverseEdge)) {
              countedEdges.add(inverseEdge);
              graph.updateEdgeAttribute(inverseEdge, 'hidden', () => true);
              graph.updateEdgeAttribute(inverseEdge, 'declined', () => true);
              graph.removeEdgeAttribute(inverseEdge, '_confidence');
              setMarkedEdges(markedEdges + 1); 
            }
          });
          countedEdges.add(selectedEdge); // Aggiungi anche l'arco principale all'insieme dopo aver gestito l'inverso
        }
    };
  
    countEdge(selectedEdge);
  };

  //FAMILY OF EDGES: view only edges selected with a option filter: view all edge, view not predicted edges, view only predicted edges
  useEffect(() => {
    Object.entries(viewEdgeFamily).forEach(([key, value]) => {
      Object.entries(graph.edges()).forEach(([id, edge]) => {
        const edgeAttributes = graph.getEdgeAttributes(edge);
  
        const isConfident = edgeAttributes._confidence !== undefined;
        const isDeclined = edgeAttributes.declined === false;
        key = key.replace(/_/g, " ");
        // Log aggiuntivi per debug
        console.log("Processing edge:", edge, "Attributes:", edgeAttributes, "isConfident:", isConfident);
  
        // Questo mostra l'arco corrente indipendentemente da _confidence
        console.log("Key:", key, "Edge Label:", edgeAttributes.label);
  
        if (key === 'all') {
          const showAll = value.view || value.prediction;
  
          if (showAll) {
            if (value.view && !value.prediction) {
              if (isConfident) {
                graph.updateEdgeAttribute(edge, 'hidden', () => true);
              } else {
                graph.updateEdgeAttribute(edge, 'hidden', () => false);
              }
            } else if (!value.view && value.prediction) {
              if (!isConfident) {
                graph.updateEdgeAttribute(edge, 'hidden', () => true);
              } else {
                graph.updateEdgeAttribute(edge, 'hidden', () => false);
              }
            } else {
              graph.updateEdgeAttribute(edge, 'hidden', () => false);
            }
          } else {
            graph.updateEdgeAttribute(edge, 'hidden', () => true);
          }
        } else {
          if (edgeAttributes.label == key) {
            if (value.view) {
              console.log("Showing edge for family:", key, edgeAttributes);
              graph.updateEdgeAttribute(edge, 'hidden', () => false);
  
              if (isConfident && !value.prediction) {
                graph.updateEdgeAttribute(edge, 'hidden', () => true);
              }
            } else {
              graph.updateEdgeAttribute(edge, 'hidden', () => true);
  
              if (isConfident && value.prediction) {
                graph.updateEdgeAttribute(edge, 'hidden', () => false);
              }
            }
          }
        }
      });
    });
  }, [viewEdgeFamily, graph]);
  
  
  
  


  return (
    <div>
      <EventsGraph passOnNode={setCurrentNode} passOnEdge={setCurrentEdge} posx={setPosX} posy={setPosY} pressOnNode={setSelectedNode} pressOnEdge={setSelectedEdge} viewMenuNode={setContextMenuNodeVisible} viewMenuEdge={setContextMenuEdgeVisible} />       {/*current is the edge/node the mouse is on, selected is the selected edge/node*/}

    {/*VIEW THE ATRIBUTE WHEN MOUSE IS ON NODE OR EDGE*/}
      {currentNode && (
        <div
          style={{
            position: 'absolute',
            left: posx,
            top: posy,
            background: 'white',
            border: '1px solid #ccc',
            textAlign: 'center',
            padding: '2%',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            opacity: 0.8,
            pointerEvents: 'none'
          }}
        >
          <p style={{color: graph.getNodeAttribute(currentNode, 'color'), borderBottom: '1px solid black', fontWeight:'bold', marginBottom: 0, marginTop: 0}}>{graph.getNodeAttribute(currentNode, 'label')}</p>
          <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center'}}>
              <tbody>
                <tr>
                  <th style={{color: graph.getNodeAttribute(currentNode, 'color')}}>Type:</th>
                  <td style={{color: graph.getNodeAttribute(currentNode, 'color')}}>
                    {graph.getNodeAttribute(currentNode, 'family')}
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
      )}

      {currentEdge && (
        <div
        style={{
          position: 'absolute',
          left: posx,
          top: posy,
          background: 'white',
          border: '1px solid #ccc',
          textAlign: 'center',
          padding: '2%',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          opacity: 0.8,
          pointerEvents: 'none'
        }}
        >
          <p style={{color: graph.getEdgeAttribute(currentEdge, 'color'), borderBottom: '1px solid black', fontWeight:'bold', marginBottom: 0, marginTop: 0}}>{graph.getEdgeAttribute(currentEdge, 'label')} </p>
          <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center'}}>
              <tbody>
                <tr>
                  <th style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>Source:</th>
                  <td style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>
                    {graph.getNodeAttribute(graph.source(currentEdge), 'label')}
                  </td>
                </tr>
                <tr>
                  <th style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>Target:</th>
                  <td style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>
                    {graph.getNodeAttribute(graph.target(currentEdge), 'label')}
                  </td>
                </tr>
                {graph.getEdgeAttribute(currentEdge, '_confidence') !== undefined &&
                <tr>
                  <th style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>Confidence:</th>
                  <td style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>
                    {graph.getEdgeAttribute(currentEdge,'_confidence')}
                  </td>
                </tr>
                }
                <tr>
                  <th style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>Bidirectional:</th>
                  <td style={{color: graph.getEdgeAttribute(currentEdge, 'color')}}>
                    {checkBidirectional(currentEdge)}
                  </td>
                </tr>
                
              </tbody>
          </table>
        </div>
      )}
      
    {/*VIEW THE MENU WHEN RIGHT MOUSE CLICK ON NODE/EDGE*/}
      {contextMenuNodeVisible && (
        <div
          style={{
            position: 'absolute',
            left: posx,
            top: posy,
            background: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            opacity: 1,
          }}
        >
          <MenuNode viewMenuNode={setContextMenuNodeVisible} myNodeSelected={selectedNode} viewName={handleShowName} viewAttributes={handleNodeAttributes} viewNeighbors={handleNeighbors} viewGraph={handleShowAllNodes} hideNode={handleHide}  />
        </div>
        )}

      {contextMenuEdgeVisible && (
        <div
          style={{
            position: 'absolute',
            left: posx,
            top: posy,
            background: 'white',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            opacity: 1,
          }}
        >
          <MenuEdge viewMenuEdge={setContextMenuEdgeVisible} myEdgeSelected={selectedEdge} viewAttributes={handleEdgeAttributes} viewExtreme={handleNeighbors} viewGraph={handleShowAllNodes} hideEdge={handleHide} myAcceptEdge={acceptEdge} myDeclineEdge={declineEdge} valueOfConfidence={graph.getEdgeAttribute(selectedEdge,'_confidence')} />
        </div>
        )}
    </div>
  );

}



export default LoadGraph;


//ADD NODE (after click on button)
  /*const handleNodeAdd = () => {
    const newNodeId = "78.0";
    const newNodeAttributes = {
      "x": 256.50653,
      "y": -122.75543,
      "size": 28.857143,
      "labels": "new",
      "label": "New Node",
      "color": "#D44028"
    };

    if (graph.hasNode(newNodeId)===false){
      graph.addNode(newNodeId, newNodeAttributes)
    }else{
      console.log("Nodo con chiave già presente")
    }

  };*/
