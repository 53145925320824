import { lab } from "d3";
import React, { useEffect, useState } from "react";

const CustomForm3 = ({ labelOfMyEdges, viewEdgesPrediction }) => {
  const [viewEdges, setViewEdges] = useState({});
  const [myError, setError] = useState(false);

  useEffect(() => {
    console.log(labelOfMyEdges, viewEdgesPrediction);
    
    if (!Array.isArray(labelOfMyEdges)) {
      setError(true); // Attiva errore se non è un array
    } else {
      setError(false); // Disabilita errore se labelOfMyEdges è valido

      // Initialize all checkboxes to true when the component mounts
      const initialCheckedState = {};
      initialCheckedState["all"] = { view: true, prediction: true };

      labelOfMyEdges.forEach((family) => {
        initialCheckedState[family] = { view: true, prediction: true };
      });

      setViewEdges(initialCheckedState);
    }
  }, [labelOfMyEdges]);

  const handleCheckboxChange = (family, field) => {
    if (family === "all") {
      setViewEdges((prevViewEdges) => {
        const newState = !prevViewEdges.all[field];
        const updatedViewEdges = {};
  
        // Aggiorna tutti i tipi di archi seguendo lo stato della casella "all"
        Object.keys(prevViewEdges).forEach((key) => {
          updatedViewEdges[key] = {
            ...prevViewEdges[key],
            [field]: newState, // Imposta tutte le caselle con lo stesso stato
          };
        });
  
        return updatedViewEdges;
      });
    } else {
      // Gestione normale per un singolo tipo di arco
      setViewEdges((prevViewEdges) => ({
        ...prevViewEdges,
        [family]: {
          ...prevViewEdges[family],
          [field]: !prevViewEdges[family][field],
        },
      }));
    }
  };
  

  useEffect(() => {
    viewEdgesPrediction(viewEdges);
  }, [viewEdges, viewEdgesPrediction]);

  return (
    <div style={styles.container}>
      {myError ? (
        <p>There are no edges to view.</p>
      ) : (
        Array.isArray(labelOfMyEdges) && labelOfMyEdges.length > 0 ? (
          <div style={{ maxHeight: "15vh", overflowY: "auto" }}>
            <table style={{ width: "100%", textAlign: "center", margin: "auto" }}>
              <thead style={{ fontSize: "70%" }}>
                <tr>
                  <th style={{ borderBottom: "2px solid black" }}>Not predicted</th>
                  <th style={{ borderBottom: "2px solid black" }}>Type of Edges</th>
                  <th style={{ borderBottom: "2px solid black" }}>Predicted</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ borderBottom: "1px solid black" }}>
                    <input
                      type="checkbox"
                      style={styles.checkbox}
                      onChange={() => handleCheckboxChange("all", "view")}
                      checked={viewEdges["all"]?.view || false}
                    />
                  </td>
                  <td style={{ borderBottom: "1px solid black" }}>
                    <strong style={styles.strong}>View all types</strong>
                  </td>
                  <td style={{ borderBottom: "1px solid black" }}>
                    <input
                      type="checkbox"
                      style={styles.checkbox}
                      onChange={() => handleCheckboxChange("all", "prediction")}
                      checked={viewEdges["all"]?.prediction || false}
                    />
                  </td>
                </tr>

                {labelOfMyEdges.map((family) => (
                  <tr key={family}>
                    <td style={{ borderBottom: "1px solid black" }}>
                      <input
                        type="checkbox"
                        id={family}
                        style={styles.checkbox}
                        onChange={() => handleCheckboxChange(family, "view")}
                        checked={viewEdges[family]?.view || false}
                      />
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      <strong style={styles.strong}>
                        {family.replace(/_/g, " ")}
                      </strong>
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      <input
                        type="checkbox"
                        style={styles.checkbox}
                        onChange={() => handleCheckboxChange(family, "prediction")}
                        checked={viewEdges[family]?.prediction || false}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <span style={styles.subtitles}>
            Check edge types you want to view
            </span>
          </div>
        ) : (
          <p>No edges available to display.</p>
        )
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: "auto",
    backgroundColor: "white",
    paddingTop: 0,
    paddingLeft: 7,
    paddingRight: 7,
    paddingBottom: 5,
    textAlign: "center",
  },
  subtitles: {
    fontSize: "70%",
    color: "black",
  },
  strong: {
    fontSize: "70%",
    marginBottom: 0,
    whiteSpace: "nowrap",
  },
  checkbox: {
    marginRight: "2%",
    cursor: "pointer",
    transform: "scale(1.5)",
  },
};

export default CustomForm3;
