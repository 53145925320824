import "@react-sigma/core/lib/react-sigma.min.css";
import * as React from 'react';
import { MenuItem, MenuList} from "@mui/material";

function MenuNode({viewMenuNode, myNodeSelected, viewName, viewAttributes, viewNeighbors, viewGraph, hideNode}) {

  //OPEN A NEW TAB TO VIEW THE NODE
  const handleShowName = () => {
    if (myNodeSelected) { 
      viewName();
      viewMenuNode(false);
    }
  }

  //MANAGING THE DISPLAY OF NODE ATTRIBUTES
  const handleNodeAttributes = () => {
    if (myNodeSelected) { 
      viewAttributes();
      viewMenuNode(false);
    }
  }

  //MANAGING THE VISUALIZATION OF THE NEIGHBORS OF A NODE: only the nearby nodes
  const handleNodeNeigh = () => {
    if (myNodeSelected) { 
      viewNeighbors();
      viewMenuNode(false);
    }
  }

  //MANAGING THE VISUALIZATION OF THE NEIGHBORS OF A NODE: ALL NODES
  const handleNodeGraph = () => {
    if (myNodeSelected) { 
      viewGraph();
      viewMenuNode(false);
    }
  }

  //MANAGING THE DELETION OF A NODE
  const handleNodeHide = () => {
    if (myNodeSelected) {   
      hideNode();
      viewMenuNode(false);
    }
  };


  return (
    <MenuList>
      <MenuItem onClick={handleShowName}>Show uri</MenuItem>
      <MenuItem onClick={handleNodeAttributes}>View attributes</MenuItem>
      <MenuItem onClick={handleNodeNeigh}>View neighbors</MenuItem>
      <MenuItem onClick={handleNodeGraph}>View graph</MenuItem>
      <MenuItem onClick={handleNodeHide}>Hide node</MenuItem>
    </MenuList>
  );
}

export default MenuNode;