import React, { useEffect,useState } from "react";
import '../style/Modal.css';

function ModalAfterChoice({ data }) {
  
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  
  useEffect(() => {

    if (Object.keys(data).length === 0){
      setIsLoading(true);
    }
    if (data && Object.keys(data).length > 0 && typeof data !== "string") {
      setIsLoading(false);
      setError(false);
    }
    if (typeof data === "string"){
      setIsLoading(true);
      setError(true)
    }
  }, [data]);

  const handleCloseChoice = () => {
    window.location.reload();
  };
  

  return (
    <div className="fade modal modal-lg" id="modalAfterChoice">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Creating the graph...</h1>
            </div>
            <div className="modal-body">
            { error ? (<div className="text-center">
                        <p className="text-danger">Error: didn't find triple to extract, please double check parameters and try again</p>
                        <div className="modal-foot text-center">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleCloseChoice}>Close</button>
                        </div>
                      </div>) 
                    : (isLoading ? ( //show spinner if data is loading
                        <div className="text-center">
                            <div className="spinner-border" role="status"></div>
                        </div>
                        ) : (<div className="text-center">
                          <p className="text-success">Your graph has been imported successfully, please close the window to view it</p>
                          <div className="modal-foot text-center">
                              <button type="button" className="btn btn-success" data-bs-dismiss="modal" >Close</button>
                          </div>
                        </div>)
                      )
              }
            </div>
            <br></br>
            
          </div>
        </div>
      </div>
  );
}  

export default ModalAfterChoice;