// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
    position: fixed;
    top: 2px;
    right: -30%; /*from start going out of schreen*/
    height: 99.3%;
    width: 20%;
    background-color: #fff;
    border: 1px solid rgba(94, 91, 91, 0.719);
    transition: right 0.3s ease-in-out;
    z-index: 100;
    padding: 3vh 1vh;
    text-align: left;
    border-radius: 10px;
  }
  
  
  .menu.open {
    right: 3px; /* brings the menu into the screen when it is open */
  }
  
  
  .closeButton {
    position: absolute;
    top: 2%;
    height: 5vh;
    width: 5vh;
    right: 2%;
    cursor: pointer;
    font-size: 1em;
    background-color:transparent;
    padding: 1%;
    color: red;
    border-radius: 20%;
  }
  

  .menuItemButton {
    width: 100%;
    border-top: 1px solid gray;
    border-bottom: 1px solid white;
    border-left: none;
    border-right: none;
    background-color: transparent;
    margin: 0;
    width: 100%;
    padding: 2vh;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
  }

  .menu h3 {
    margin-left: 5%; 
    margin-top: 0;
}
  `, "",{"version":3,"sources":["webpack://./src/style/MenuOptions.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,WAAW,EAAE,kCAAkC;IAC/C,aAAa;IACb,UAAU;IACV,sBAAsB;IACtB,yCAAyC;IACzC,kCAAkC;IAClC,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;;EAGA;IACE,UAAU,EAAE,oDAAoD;EAClE;;;EAGA;IACE,kBAAkB;IAClB,OAAO;IACP,WAAW;IACX,UAAU;IACV,SAAS;IACT,eAAe;IACf,cAAc;IACd,4BAA4B;IAC5B,WAAW;IACX,UAAU;IACV,kBAAkB;EACpB;;;EAGA;IACE,WAAW;IACX,0BAA0B;IAC1B,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;IAClB,6BAA6B;IAC7B,SAAS;IACT,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,iBAAiB;EACnB;;EAEA;IACE,eAAe;IACf,aAAa;AACjB","sourcesContent":[".menu {\r\n    position: fixed;\r\n    top: 2px;\r\n    right: -30%; /*from start going out of schreen*/\r\n    height: 99.3%;\r\n    width: 20%;\r\n    background-color: #fff;\r\n    border: 1px solid rgba(94, 91, 91, 0.719);\r\n    transition: right 0.3s ease-in-out;\r\n    z-index: 100;\r\n    padding: 3vh 1vh;\r\n    text-align: left;\r\n    border-radius: 10px;\r\n  }\r\n  \r\n  \r\n  .menu.open {\r\n    right: 3px; /* brings the menu into the screen when it is open */\r\n  }\r\n  \r\n  \r\n  .closeButton {\r\n    position: absolute;\r\n    top: 2%;\r\n    height: 5vh;\r\n    width: 5vh;\r\n    right: 2%;\r\n    cursor: pointer;\r\n    font-size: 1em;\r\n    background-color:transparent;\r\n    padding: 1%;\r\n    color: red;\r\n    border-radius: 20%;\r\n  }\r\n  \r\n\r\n  .menuItemButton {\r\n    width: 100%;\r\n    border-top: 1px solid gray;\r\n    border-bottom: 1px solid white;\r\n    border-left: none;\r\n    border-right: none;\r\n    background-color: transparent;\r\n    margin: 0;\r\n    width: 100%;\r\n    padding: 2vh;\r\n    cursor: pointer;\r\n    text-align: left;\r\n    font-weight: bold;\r\n  }\r\n\r\n  .menu h3 {\r\n    margin-left: 5%; \r\n    margin-top: 0;\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
