import React, { useState } from "react";
import "../style/MenuOptions.css";
import { FaChevronDown, FaChevronRight } from "react-icons/fa"; //import the icons of the darts



const MenuOptions = ({ isOpen, onClose, menuItems, onItemClick }) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (itemId) => {
    if (activeItem === itemId) {
      setActiveItem(null);    //close vary options
    } else {
      setActiveItem(itemId);    //set new option open
    }
    onItemClick(itemId);
  };

  return (
    <div className={`menu ${isOpen ? "open" : ""}`}>

      <button className="closeButton" onClick={onClose}>
        X
      </button>

      <h3>Options</h3>
      <div>
        {menuItems.map((menuItem) => (
          <div key={menuItem.id}>
            <button
              className="menuItemButton"
              onClick={() => handleItemClick(menuItem.id)}    //set new option element
            >
              <span>{menuItem.label}</span>
              {menuItem.content && (
                <span>
                  {isOpen && activeItem === menuItem.id ? (
                    <FaChevronDown />
                  ) : (
                    <FaChevronRight />
                  )}
                </span>
              )}
            </button>

            {activeItem === menuItem.id && isOpen && (
              <div>
                {menuItem.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuOptions;