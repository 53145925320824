import React, { useEffect, useState } from "react";
import "../style/Modal.css";
import ModalAfterChoice from "./modalAfterChoice";

function ModalChoice({ pubMedId, onDataUpdate }) {

  const rel = [
    "mirna-go",
    "mirna-disease",
    "mirna-chemical",
    "lncrna-disease",
    "lncrna-mrna",
    "lncrna-gene",
    "snp-snorna",
    "lncrna-go",
    "snorna-go",
    "mrna-chemical",
    "mrnavaccine-disease",
    "snorna-disease",
    "snorna-gene",
    "snorna-lncrna",
    "mirna-gene",
    "mirna-mirna",
    "snorna-mirna",
  ];

  const [myChoice, setMyChoice] = useState([]);
  const [myAfterChoice, setMyAfterChoice] = useState([]);
  const [error, setError] = useState(false);

  const handleCheckboxChange = (choice) => {
    //setMyChoice(choice)
    setMyChoice((c) =>
      c.includes(choice) ? c.filter((id) => id !== choice) : [...c, choice]
    );
    //console.log("SCEWLTA:",myChoice);
  };

  const handleConfirmChoice = async () => {

    //* Handle backdrop removal manually
    const backdrops = document.querySelectorAll('.modal-backdrop');
    for (let i = 0;i < 2;i++) {
      backdrops[i].remove();
    }

    setMyAfterChoice([]);
    const data = await getTriple();
    if (data === "Error") {
      setError(true);
    } else {
      setError(false);
    }
    setMyAfterChoice(data);
    onDataUpdate(data);
  };

  const getTriple = async () => {
    try {
      const query = `https://bio-viber-api.anacleto.di.unimi.it/get_graph?pmid=${pubMedId}&relation=${rel[1]}}`; // constant relation for now
      //${pubMedId}   32683841
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error.message);
      const data = "Error";
      return data;
    }
  };


  return (
    <div>
      <ModalAfterChoice data={myAfterChoice} />
      <div className="modal fade modal-xs" id="modalChoice">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Select the relations
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered center-text align-middle">
                  <thead>
                    <tr>
                      <th scope="col">relation</th>
                      <th scope="col">selected</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rel.map((item, index) => (
                      <tr key={index} className="separated-row">
                        <td>{item}</td>
                        <td className="center-text narrow-column">
                          <input
                            type="checkbox"
                            className="large-checkbox"
                            onChange={() => handleCheckboxChange(item)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="modal-foot text-center">
              <button
                type="button"
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#modalAfterChoice"
                onClick={handleConfirmChoice}
                disabled={myChoice.length === 0}
              >
                Confirm
              </button>
            </div>
            <br></br>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalChoice;
